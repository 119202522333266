import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  height: 100vh;
  padding: 40px;
  background-color: #ffc69d;
  text-emphasis-color: #223126;
`;

const SocialEntry = styled.div`
  display: flex;
  /* align-items: center;
  justify-content: center; */
  text-align: left;
  width: 100%;
  margin: 10px 0;
`;

const StyledButton = styled.button`
  grid-column: span 1;
  color: rgb(35, 35, 35);
  border: 1px solid rgb(35, 35, 35);
  padding: 10px;
  width: 220px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.7);
  text-align: left;
  margin: 20px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  /* margin-top: 10px; */
  transition-duration: 0.4s;
  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

// to be aligned in left
const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  width: 24px !important;
  height: auto;
  align-self: left;
`;

const InstagramButton = styled(StyledButton)`
  background-color: #fd946a;
`;

const TwitterButton = styled(StyledButton)`
  background-color: #1da1f2;
`;

const LinkedInButton = styled(StyledButton)`
  background-color: #3a96f2;
`;

const EmailButton = styled(StyledButton)`
  background-color: #fff2d4;
`;
const GithubButton = styled(StyledButton)`
  background-color: #5e5e5e;
  color: #fff;
`;

const SmallGrayParagraph = styled.h3`
  color: gray;
  margin: 0;
  padding: 0;
  padding-bottom: 5px;
  font-size: 0.8em;
`;

const BoldBlackLink = styled.a`
  color: black;
  /* font-weight: light; */
  text-decoration: none;
  &:hover {
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
	font-weight: 900px;
	color: blue;
  }
`;

const ContactPage = () => {
  const user = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.role === "admin") {
      navigate("/user-stats");
    }
  }, [user, navigate]);

  return (
    <ContactContainer>
      <h1>Contact Page</h1>
      <SocialEntry>
        <InstagramButton
          onClick={() =>
            window.open(
              "https://www.instagram.com/nassos_bountioukos/",
              "_blank"
            )
          }
        >
          <Icon icon={faInstagram} />
          <br />
          @nassos_bountioukos
        </InstagramButton>
      </SocialEntry>
	  <SmallGrayParagraph>
    If you find the guy that has the {""}
    <BoldBlackLink href="https://www.instagram.com/n450s/" target="_blank" rel="noopener noreferrer">@n450s</BoldBlackLink> 
    {""} account, please lmk.
  </SmallGrayParagraph>
	  
      {/* <SocialEntry>
		  <TwitterButton onClick={() => window.open('https://twitter.com/yourusername', '_blank')}>
			<Icon icon={faTwitter} />
			Twitter
		  </TwitterButton>
		</SocialEntry> */}
      {/* <SocialEntry>
		  <LinkedInButton onClick={() => window.open('https://www.linkedin.com/in/yourusername/', '_blank')}>
			<Icon icon={faLinkedin} />
			Nassos Bountioukos Spinaris
		  </LinkedInButton>
		</SocialEntry> */}
      <SocialEntry>
        <GithubButton
          onClick={() =>
            window.open("https://github.com/n45os", "_blank")
          }
        >
          <Icon icon={faGithub} />
          Github
        </GithubButton>
      </SocialEntry>
      <SocialEntry>
        <EmailButton
          onClick={() =>
            (window.location.href = "mailto:nassosbountioukos@gmail.com")
          }
        >
          <Icon icon={faEnvelope} />
          Email
        </EmailButton>
      </SocialEntry>
    </ContactContainer>
  );
};

export default ContactPage;
