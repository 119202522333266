import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { url } from "src/api";
import Modal from "./modal";
import { RootState } from "src/store/reducers/types";



interface ModalProps {
	onClose: () => void;
	onConfirm: () => void;
	children: ReactNode;
}

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  width: auto;
  max-width: 500px;
  margin: auto;
  margin: 0 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Button = styled.button`
  border: 1px solid rgb(35, 35, 35);
  box-shadow: 3px 3px rgba(0, 0, 0, 0.7);
  background-color: #ffa6f5;
  justify-content: start;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  color: rgb(35, 35, 35);
  font-size: 1rem;
  transition-duration: 0.4s;
  width: auto;
  height: 43px;

  &:hover {
    background-color: #d6046d; // Adjust hover color as needed
  }
`;
const VerificationInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const StyledParagraph = styled.p`
  color: #333;
  font-size: 1rem;
  margin: 10px 0;
`;

const VerificationComponent = () => {
	const [showVerificationModal, setShowVerificationModal] = useState(false);
	const [verificationCode, setVerificationCode] = useState('');
  const [emailSent, setEmailSent] = useState(false);
	// is verified
	const [isVerified, setIsVerified] = useState(false);
	const user = useSelector((state: RootState) => state.auth);


	const handleVerifyCode = async () => {
		// Call the backend API to verify the code
		try {
			const response = await axios.post(url + '/api/verify/verify-code', {
				code: verificationCode,
				// Add any other required fields
			}, {
				headers: {
					'x-auth-token': localStorage.getItem('token'), // Adjust as needed
				},
			});

			if (response.status === 200) {
				// Handle successful verification
				onClose(); // Close the modal
				// Further actions like showing a success message
				// show a toast notification
				// toast("Verification email sent. Please check your inbox.");
				toast("Verification successful");
			} else {
				// Handle unsuccessful verification
				// Show an error message
			}
		} catch (error) {
			console.error('Error verifying code:', error);
			// Handle error
		}
	};


	const handleSendVerificationEmail = async () => {
		try {
			const response = await axios.post(url + '/api/verify/send-verification-code', {}, {
				headers: {
					'x-auth-token': localStorage.getItem('token'),
				},
			});

			if (response.status === 200) {
        setEmailSent(true); 
				setShowVerificationModal(true);
				toast("Verification email sent. Please check your inbox.");
			} else {
				console.error('Failed to send verification email');
			}
		} catch (error) {
			console.error('Error sending verification email:', error);
		}
	};

	const onClose = () => {
    setShowVerificationModal(false);
    setEmailSent(false);
    // Any additional logic you want to execute when the modal is closed
  };


  return (
    <>
      {user._id && !isVerified && (
        <Button onClick={() => setShowVerificationModal(true)}>
          Verify Email
        </Button>
      )}

      {showVerificationModal && (
        <ModalBackdrop onClick={onClose}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <StyledParagraph>
              {emailSent ? "Enter the verification code sent to your email." : "Send a verification code to your email."}
              <p>{`The email address associated with your account is ${user.email}`}</p>
            </StyledParagraph>
            {!emailSent ? (
              // Show "Send Email" button if email hasn't been sent
              <Button onClick={handleSendVerificationEmail}>Send Email</Button>
            ) : (
              // Show verification input and buttons if email has been sent
              <>
                <VerificationInput
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Enter verification code"
                />
                <ButtonGroup>
                  <Button onClick={handleVerifyCode}>Verify</Button>
                  <Button onClick={onClose}>Cancel</Button>
                </ButtonGroup>
              </>
            )}
          </ModalContent>
        </ModalBackdrop>
      )}
    </>
  );
};




export default VerificationComponent;
