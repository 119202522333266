import axios, { AxiosError } from "axios";
import React, { useState, useEffect } from "react";
import { url as apiUrl } from "../../api";

export type StoreInfo = {
  title: string;
  id: string;
  creator?: string;
  description?: string;
  dateAdded?: Date;
  dateMade?: Date;
  imageId?: string;
  addedBy?: string;
  price?: number;
  stock?: number;
  size?: string;
  merchType?: string;
  productNumber?: number;
  selected?: boolean;
  inGallery?: boolean;
  fileNames: string[]
  likes?: string[];
  likesCount?: number;
};

export const useLoadInfo = () => {
  const url = apiUrl as string; // Add a type assertion here
  const [storeInfos, setStoreInfos] = useState<StoreInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchStoreInfo = async () => {
    setLoading(true);
    try {
      const response = await axios.get(url + `/api/store`);
      setStoreInfos(
        response.data.map((info: any) => ({
          title: info.title,
          creator: info.creator,
          id: info._id,
          description: info.description,
          dateAdded: new Date(info.dateAdded),
          dateMade: new Date(info.dateMade),
          imageId: info.imageId,
          addedBy: info.addedBy,
          price: info.price,
          stock: info.stock,
          size: info.size,
          merchType: info.merchType,
          productNumber: info.productNumber,
          selected: info.selected,
          inGallery: info.inGallery,
          fileNames: info.fileNames,
          likes: info.likes,
          likesCount: info.likesCount
        }))
      );
      setLoading(false);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.status === 404) {
        setStoreInfos([]);
        setLoading(false);
        console.log("There is no merch yet.")
      } else {
        console.error("Error fetching store INFO:", axiosError);
      }
    }
  };


  useEffect(() => {
    fetchStoreInfo();
  }, [url]);

  return { storeInfos, loading };
};
