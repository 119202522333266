import React, { useState } from "react";
import styles from "./stats-box.module.css";
import { useLoadStats } from "./useGetAllStats";

interface Stats {
  totalUsers: number;
  totalFriendsFamilyUsers: number;
  totalAOTD: number;
  totalMessages: number;
  totalTokensUsed: number;
  usersWithTheMostTokens: Array<{ username: string; chatTokensUsed: number }>;
  aotdAuthors: Array<{ _id: string; postsCount: number }>;
  usersToDisplay: Array<{ username: string; chatTokensUsed: number }>;
}

const FullStats = () => {
  const { stats } = useLoadStats();

  const [showAllUsers, setShowAllUsers] = useState(false);

  const usersWithTheMostTokens = stats?.usersWithTheMostTokens || [];

  // Determine which users to display based on the state
  const usersToDisplay = showAllUsers ?
    usersWithTheMostTokens :
    usersWithTheMostTokens.slice(0, 3);

  if (!stats) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.userInfo}>
      <div className={styles.infoBox}>
        <strong>total users:</strong> {stats.totalUsers}
      </div>
      <div className={styles.infoBox}>
        <strong>total AOTDs:</strong> {stats.totalAOTD}
      </div>
      <div className={styles.infoBox}>
        <strong>total messages:</strong> {stats.totalMessages}
      </div>
      <div className={styles.infoBox}>
        <strong>total F&F:</strong> {stats.totalFriendsFamilyUsers}
      </div>
      <div className={styles.infoBox}>
        <strong>total tokens used:</strong> {stats.totalTokensUsed}
      </div>
      <div className={styles.infoBox}>
        <strong>users w/ the most tokens:</strong>

        <table className={styles.userTokensTable}>
          <thead>
            <tr>
              <th>Username</th>
              <th>Tokens</th>
            </tr>
          </thead>
          <tbody>
            {usersToDisplay.map((user, index) => (
              <tr key={index}>
                <td>{user.username}</td>
                <td>{user.chatTokensUsed}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Toggle Button */}
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        {stats.usersWithTheMostTokens.length > 3 && (
          <button className={styles.toggleButton} onClick={() => setShowAllUsers(!showAllUsers)}>
            {showAllUsers ? <i className="fa fa-chevron-up"></i> : <i className="fa fa-chevron-down"></i>}
          </button>
        )}

      </div>
    </div>
  );
};

export default FullStats;
