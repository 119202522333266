import React, { useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  InputToolbox,
  SendButton,
  AttachmentButton,
} from "@chatscope/chat-ui-kit-react";
// import { getMessages, sendMessage } from './actions/chatActions';

const ChatBot = () => {

  // const messages = useSelector((state) => state.chat);
  // const dispatch = useDispatch();

  const [chatOpen, setChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);

  const toggleChat = () => {
    setChatOpen(!chatOpen);
  };

  const sendMessage = (messageText) => {
    setMessages([
      ...messages,
      {
        message: messageText,
        sentTime: new Date().toLocaleTimeString(),
        sender: "User",
        direction: "incoming",
        position: "single",
      },
    ]);
  };

  // useEffect(() => {
  //   dispatch(getMessages());
  // }, [dispatch]);

  // const handleSendMessage = (text) => {
  //   dispatch(sendMessage(text));
  // };

  console.log(`messsagas: ${messages}`)

  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        width: "100vw",
      }}
    >
      {/* Your other content */}

      {chatOpen && (
        <div
          style={{
            position: "fixed",
            bottom: "60px",
            right: "20px",
            zIndex: 1000,
            borderRadius: "10px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "300px",
              height: "400px",
              borderRadius: "10px",
            }}
          >
            <MainContainer style={{ fontSize: "1em" }}>
              <ChatContainer>
                
                <MessageList>
                  {messages.map((msg, index) => (
                    <Message  model={msg} />
                  ))}
                </MessageList>
                <MessageInput
                  attachButton={false}
                  placeholder="Type message here"
                  onSend={(e) => {
                    if (e.key === "Enter") {
                      console.log(e.target.value);
                      sendMessage(e.target.value);
                      e.target.value = "";
                    }
                  }}
                />
              </ChatContainer>
            </MainContainer>
          </div>
        </div>
      )}

      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "#007BFF",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          zIndex: 999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
        onClick={toggleChat}
      >
        {/* Add a "+" or chat icon here */}
        <span style={{ color: "white", fontSize: "24px" }}>+</span>
      </div>
    </div>
  );
};

export default ChatBot;
