import React, { useState } from 'react';
import axios from 'axios';
import { url as apiUrl } from '../../../api'; // Adjust this import

const AddRecipe = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [ingredients, setIngredients] = useState('');
  const [instructions, setInstructions] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    try {
      const token = localStorage.getItem('token');
      const headers = token ? { 'x-auth-token': token } : {};

      // Process ingredients input into an array of objects
      const ingredientArray = ingredients.split(',').map(ingredient => {
        const parts = ingredient.split(':').map(part => part.trim());
        return { name: parts[0], quantity: parts[1] };
      });

      // Process instructions into an array of strings
      const instructionArray = instructions.split('.').map(instruction => instruction.trim());

      await axios.post(`${apiUrl}/api/recipes`, {
        title,
        description,
        ingredients: ingredientArray,
        instructions: instructionArray
      }, { headers });

      // Handle success here (e.g., show a success message, clear form, redirect)
    } catch (error) {
      // Handle error here
      setError('Failed to add recipe. Please try again.');
    }
  };
  const formStyle = {
    marginBottom: '10px',
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={formStyle}>
        <label>
          Title:
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
        </label>
      </div>

      <div style={formStyle}>
        <label>
          Description:
          <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description"></textarea>
        </label>
      </div>

      <div style={formStyle}>
        <label>
          Ingredients:
          <textarea value={ingredients} onChange={(e) => setIngredients(e.target.value)} placeholder="Ingredients (comma-separated, format: 'name: quantity')"></textarea>
        </label>
        <div style={{fontSize: '0.8rem'}}>Enter ingredients in the format 'ingredient name: quantity', separated by commas. For example, 'Flour: 2 cups, Sugar: 1 cup'.</div>
      </div>

      <div style={formStyle}>
        <label>
          Instructions:
          <textarea value={instructions} onChange={(e) => setInstructions(e.target.value)} placeholder="Instructions (period-separated)"></textarea>
        </label>
        <div style={{fontSize: '0.8rem'}}>Enter each step of the instructions separated by a period. For example, 'Mix ingredients. Bake for 20 minutes.'</div>
      </div>

      <button type="submit">Add Recipe</button>
      {error && <p>{error}</p>}
    </form>
  );
};

export default AddRecipe;
