import { toast } from "react-toastify";
import jwpDecode from "jwt-decode";
import jwtDecode from "jwt-decode";

const initialState = {
  token: localStorage.getItem("token"),
  email: null,
  username: null,
  role: null,
  _id: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOAD":
    case "SIGNIN":
    case "SIGNUP":
    case "CHANGE_CREDS":
      if (action.token) {
        let user = jwtDecode(action.token); // Assuming jwtDecode is the correct function
        let succMessage = "";
        if (action.type === "SIGNUP") {
          succMessage = `Welcome ${user.username}! An email has been sent to ${user.email} to verify your account.`;
        }
        if (action.type === "SIGNIN") {
          succMessage = `Welcome back ${user.username}!`;
        }
        if (succMessage !== "") {
          toast.success(succMessage, {
            position: 'top-center',
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        return {
          ...initialState,
          token: action.token,
          username: user.username,
          email: user.email,
          role: user.role,
          _id: user._id,
        };
      } else {
        toast("Can't render token", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      break;
    case "SIGNOUT":
      localStorage.removeItem("token");
      toast(
        "Hope to see you again soon!",
        {
          // place it to the top center
          position: "top-center",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      )
      return {
        token: null,
        email: null,
        username: null,
        _id: null,
      };
    case "ERROR":
      // toast.error(action.error, {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      // });
      console.log('ERRR error', action.error)
      return {
        ...state,
        token: null,
        error: action.error,
      };

    default:
      return state;
  }
};

export default authReducer;
