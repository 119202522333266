import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { url as apiUrl } from '../../../api'; // Adjust this import
import { RecipeInfo } from '../loaders/useInfo';

const EditRecipe: React.FC<{ recipeInfo: RecipeInfo }> = ({ recipeInfo }) => {
  const [title, setTitle] = useState(recipeInfo.title);
  const [description, setDescription] = useState(recipeInfo.description);
  const [ingredients, setIngredients] = useState(
    recipeInfo.ingredients.map(ing => `${ing.name}: ${ing.quantity}`).join(', ')
  );  
  const [instructions, setInstructions] = useState(recipeInfo.instructions.join('. '));
  const [difficulty, setDifficulty] = useState(recipeInfo.difficulty || ''); // Assuming difficulty is part of RecipeInfo
  const [time, setTime] = useState(recipeInfo.time || ''); // Assuming time is part of RecipeInfo
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [images, setImages] = useState(recipeInfo.images);



  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const uploadImage = async () => {
    if (selectedImage) {
      const formData = new FormData();
      formData.append('image', selectedImage);
      const token = localStorage.getItem('token');

      try {
        const response = await axios.post(`${apiUrl}/api/recipes/image/${recipeInfo.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': token
          }
        });
        // Handle success - Image uploaded
        const uploaded = `${apiUrl}/api/recipes/images/${recipeInfo.id}/${response.data.name}` 
        setImages([...images, uploaded]);
      } catch (error) {
        // Handle error
      }
    }
  };
  const deleteImage = async (name: string) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${name}`, {
        headers: {
          'x-auth-token': token
        }
      });
      // Update the local state to reflect the deletion
      const updatedImages = images.filter(img => img !== name);
      setImages(updatedImages);
      // Alternatively, re-fetch the recipe data to update the UI
    } catch (error) {
      // Handle error
    }
  };


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const token = localStorage.getItem('token');
    const headers = token ? { 'x-auth-token': token } : {};
    e.preventDefault();
    try {
      const ingredientArray = ingredients.split(',').map(ingredient => {
        const parts = ingredient.split(':').map(part => part.trim());
        return { name: parts[0], quantity: parts[1] };
      });

      // Process instructions into an array of strings
      const instructionArray = instructions.split('.').map(instruction => instruction.trim());
      await axios.put(`${apiUrl}/api/recipes/${recipeInfo.id}`, {
        title,
        description,
        ingredients: ingredientArray,
        instructions: instructionArray,
        difficulty, 
        time
      }, { headers });
      // Handle success
    } catch (error) {
      // Handle error
    }
  };

  return (
    <form style={{ display: "flex", flexDirection: "column", padding: "5px", gap: "10px" }} onSubmit={handleSubmit}>
      <div style={{ marginBottom: '10px' }}>
        <label style={{ display: 'block', fontSize: '0.8rem' }}>Title:</label>
        <textarea value={title} onChange={(e) => setTitle(e.target.value)} />
      </div>
  
      <div style={{ marginBottom: '10px' }}>
        <label style={{ display: 'block', fontSize: '0.8rem' }}>Description:</label>
        <textarea value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
      </div>
  
      <div style={{ marginBottom: '10px' }}>
        <label style={{ display: 'block', fontSize: '0.8rem' }}>Ingredients (format as "name: quantity, ..."):</label>
        <textarea value={ingredients} onChange={(e) => setIngredients(e.target.value)}></textarea>
      </div>
  
      <div style={{ marginBottom: '10px' }}>
        <label style={{ display: 'block', fontSize: '0.8rem' }}>Instructions (separate steps with a period):</label>
        <textarea value={instructions} onChange={(e) => setInstructions(e.target.value)}></textarea>
      </div>
  
      <div style={{ marginBottom: '10px' }}>
        <label style={{ display: 'block', fontSize: '0.8rem' }}>Difficulty:</label>
        <input type="number" value={difficulty} onChange={(e) => setDifficulty(e.target.value)} placeholder="Difficulty" />
      </div>
  
      <div style={{ marginBottom: '10px' }}>
        <label style={{ display: 'block', fontSize: '0.8rem' }}>Time (e.g., 30 mins):</label>
        <input type="text" value={time} onChange={(e) => setTime(e.target.value)} placeholder="Time" />
      </div>
  
      {images.map((image, index) => (
        <div key={index}>
          <img src={`${image}`} alt="Recipe" style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: "8px", boxShadow:"3px 3px 5px #222", margin: "6px"}} />
          <button type="button" onClick={() => deleteImage(image)}>Delete Image</button>
        </div>
      ))}
  
      <div style={{ marginBottom: '10px' }}>
        <label style={{ display: 'block', fontSize: '0.8rem' }}>Upload Image:</label>
        <input type="file" onChange={handleImageChange} accept="image/*" />
      </div>
      <button type="button" onClick={uploadImage} style={{ marginBottom: '10px' }}>Upload Image</button>
  
      {/* Save Changes Button */}
      <button type="submit" style={{ marginBottom: '10px' }}>Save Changes</button>
    </form>
  );  
};

export default EditRecipe;
