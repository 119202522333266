import React, { ChangeEvent, MouseEvent, useState } from "react";
import styles from "./profile-box.module.css";

interface EditableUserInfoProps {
  username: string;
  email: string;
  role: string;
  dateSigned: string;
  onSubmit: (data: any) => void;
  onCancel: () => void;
}

const EditableUserInfo: React.FC<EditableUserInfoProps> = ({
  username,
  email,
  role,
  dateSigned,
  onSubmit,
  onCancel,
}) => {
  const [userData, setUserData] = useState({
    username,
    email,
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (userData.password !== userData.confirmPassword) {
      alert("Passwords do not match.");
      return;
    }
    onSubmit({
      username: userData.username,
      email: userData.email,
      ...(userData.password ? { password: userData.password } : {}),
    });
  };

  return (
    <div className={styles.userInfo}>
      <label className={styles.infoBox}>
        <strong>username:</strong>{" "}
        <input
          name="username"
          value={userData.username}
          onChange={handleInputChange}
        />
      </label>
      <label className={styles.infoBox}>
        <strong>email:</strong>{" "}
        <input
          name="email"
          value={userData.email}
          onChange={handleInputChange}
        />
      </label>
      <label className={styles.infoBox}>
        <strong>role:</strong> {role}
      </label>
      <label className={styles.infoBox}>
        <strong>date Signed:</strong> {dateSigned}
      </label>
      <label className={styles.infoBox}>
        <strong>password:</strong>{" "}
        <input
          type="password"
          name="password"
          placeholder="******"
          onChange={handleInputChange}
        />
      </label>
      <label className={styles.infoBox}>
        <strong>Confirm Password:</strong>{" "}
        <input
          type="password"
          name="confirmPassword"
          placeholder="******"
          onChange={handleInputChange}
        />
      </label>
      <button className={styles.submitButton} onClick={handleSubmit}>
        Submit
      </button>
      <button className={styles.cancelButton} onClick={onCancel}>
        Cancel
      </button>
    </div>
  );
};

export default EditableUserInfo;
