import React, { useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GalleryItem from "./gallery_item";
import { getGallery } from "../../store/actions/galleryActions";
import { url } from "../../api";

import { toast } from "react-toastify";
import { logEvent, logPageView } from "../../analytics/GoogleAnalytics";

function checkRole(str, searchStr) {
  // convert the input string to an array by splitting at each comma
  try {
    var arr = str.split(",");
  } catch {
    return false;
  }

  // loop through the array and check if any element matches the search string
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].trim() === searchStr) {
      return true;
    }
  }
  return false;
}

const GalleryPage = (props) => {
  const dispatch = useDispatch();
  const gallery = useSelector((state) => state.galleries);
  const user = useSelector((state) => state.auth);
  console.log("state");
  console.log(gallery);
  console.log(user);

  useEffect(() => {
		document.title = 'NΛSOS Gallery'
    logPageView();
  }, []);

  useEffect(() => {
    dispatch(getGallery());
  }, [dispatch]);

  const handleImageClick = (title) => {
    logEvent("click_image", {
      image_title: title,
      category: "Gallery",
    });
  };

  return (
    <>
      <GalleryContainer>
        {/* <link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400&display=swap" rel="stylesheet"></link> */}
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossorigin
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Tillana&display=swap"
          rel="stylesheet"
        ></link>

        {(checkRole(user.role, "admin") ||
          checkRole(user.role, "gallery_manager")) && (
          <>
            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
              margin-left="auto"
              margin-right="0"
              display="inline-block"
            ></link>
            <AddArtButton to="/art/add">
              <button className="fa fa-plus" aria-hidden="true"></button>
            </AddArtButton>
          </>
        )}
        <TitleDiv>
          {user.token ? "gallery" : "gallery (login to see more)"}
        </TitleDiv>
        <Grid>
          {gallery.map((art, index) => {
            return (
              <GalleryItem
                key={art._id}
                picture={url + `/api/gallery/images/${art.imageFilename}`}
                title={art.title}
                description={art.description}
                dateMade={art.dateMade}
                creator={art.creator}
                onClick={() => handleImageClick(art.title)}
              />
            );
          })}
        </Grid>
      </GalleryContainer>
    </>
  );
};

const GalleryContainer = styled.div`
  /* display: flex; */
  /* flex-wrap: wrap; */
  background-color: #b286ffff;
  padding: 45px;
  position: relative;
`;

const BoxDiv = styled.div`
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
  margin: 20px;
`;

const Grid = styled.div`
  /* display: grid inline-grid; */
  display: grid;
  margin-top: 10px;
  /* grid-template: auto; */
  /* grid-template-columns:  20rem 20rem 20rem 20rem ;
	grid-template-rows:  30rem 30rem 30rem 30rem ;
	grid-auto-flow: row; */
  /* grid-auto-flow: column ; */
  /* grid-auto-rows: 25rem; */
  grid-gap: 4rem;
  /* grid-auto-columns: 30rem; */
  grid-template-columns: repeat(auto-fit, minmax(18rem, 0.5fr));

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`;

const AddArtButton = styled(Link)`
  display: flex;
  border: #000000;
  /* padding: 8px 16px; */
  font-size: 20px;
  color: #000000;
  text-decoration: none;

  /* display: inline-block; */
  font-weight: 600;
  /* width: 50%; */
  /* float: left; */
  margin-left: auto;
  margin-right: 0;

  justify-content: end;
  overflow: hidden;

  float: right;

  transition: font-size 0.1s;

  &:hover {
    color: #555;
    font-size: 22px;
  }
`;

const TitleDiv = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  padding-bottom: 20px;
`;

export default GalleryPage;
