const server_url = process.env.REACT_APP_SERVER_URL;
export const url = server_url;

export const setChatHeaders = (extraHeaders) => {
  const token = localStorage.getItem("token");
  const chat_token = localStorage.getItem("chat_token");

  if (!token || token === "") {
    return {
      headers: {
        ...extraHeaders,
		"chat_token": chat_token 
      },
    };
  } else {
    return {
      headers: {
        ...extraHeaders,
        "x-auth-token": token,
      },
    };
  }
};
