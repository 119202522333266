import React, { ChangeEvent, MouseEvent } from 'react';
import styles from './stats-box.module.css';

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  return date.toUTCString();
}

interface EditableUserInfoProps {
  username: string;
  userEmoji: string;
  email: string;
  role: string;
  dateSigned: string;
  lastSigned: string;
  timesSigned: number;
  chatTokensUsed: number;
  permissions: number;
  onSubmit: (data: EditableUserInfoState) => void;
  onCancel: () => void;
}

interface EditableUserInfoState {
  username: string;
  userEmoji: string;
  email: string;
  role: string;
  dateSigned: string;
  lastSigned: string;
  timesSigned: number;
  chatTokensUsed: number;
  permissions: number;
}

class EditableUserInfo extends React.Component<EditableUserInfoProps, EditableUserInfoState> {
  constructor(props: EditableUserInfoProps) {
    super(props);
    this.state = {
      username: this.props.username,
      userEmoji: this.props.userEmoji,
      email: this.props.email,
      role: this.props.role,
      dateSigned: this.props.dateSigned,
      lastSigned: this.props.lastSigned,
      timesSigned: this.props.timesSigned,
      chatTokensUsed: this.props.chatTokensUsed,
      permissions: this.props.permissions
    };
  }

  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'timesSigned' || name === 'chatTokensUsed' || name === 'permissions') {
      this.setState({ [name]: parseInt(value, 10) } as any);
    } else {
      this.setState({ [name]: value } as any);
    }
  };

  handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    this.props.onSubmit(this.state);
  }

  handleCancel = (event: MouseEvent<HTMLButtonElement>) => {
    this.props.onCancel();
  }

  render() {
    return (
		<div className={styles.userInfo}>
        <label className={styles.infoBox}><strong>username:</strong> <input name="username" value={this.state.username} onChange={this.handleInputChange} /></label>
        <label className={styles.infoBox}><strong>user emoji:</strong> <input name="userEmoji" value={this.state.userEmoji} onChange={this.handleInputChange} /></label>
        <label className={styles.infoBox}><strong>email:</strong> <input name="email" value={this.state.email} onChange={this.handleInputChange} /></label>
        <label className={styles.infoBox}><strong>role:</strong> <input name="role" value={this.state.role} onChange={this.handleInputChange} /></label>
        <label className={styles.infoBox}><strong>date signed:</strong> {formatDate(this.state.dateSigned)} </label>
        <label className={styles.infoBox}><strong>last signed:</strong> {formatDate(this.state.lastSigned)}</label>
        <label className={styles.infoBox}><strong>times signed:</strong> {this.state.timesSigned}</label>
        <label className={styles.infoBox}><strong>chat tokens: </strong>{this.state.chatTokensUsed}</label>
        <label className={styles.infoBox}>
          <strong>permissions: </strong>
          <input name="permissions" value={this.state.permissions} onChange={this.handleInputChange} type="number" />
        </label>
        <button className={styles.submitButton} onClick={this.handleSubmit}>submit</button>
        <button className={styles.cancelButton} onClick={this.handleCancel}>cancel</button>
      </div>
    );
  }
}
export default EditableUserInfo;
