import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { logPageView } from "../../analytics/GoogleAnalytics";
import ArtOfTheDay from "./art_of_the_day";
import axios from "axios";
import { url } from "../../api";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "./spinner.css";

const ArtOfTheDayPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-color: #f0f0f0;
  padding: 15px;
  height: auto;
  min-height: 100vh;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #222;
  text-align: center;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #555;
  max-width: 800px;
  text-align: center;
  margin-bottom: 2rem;
`;

const QuoteForm = styled.form`
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const QuoteInput = styled.input`
  font-size: 1rem;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 0.1rem;
`;

const SubmitButton = styled.button`
  grid-column: span 1;
  color: rgb(249, 249, 249);
  border: 1px solid rgb(35, 35, 35);
  padding: 10px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.818);
  background-color: #444;
  margin: 10px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 186px;
  transition-duration: 0.4s;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #222;
  }
`;

const GalleryButton = styled.button`
  background-color: #a033cb;
  grid-column: span 1;
  color: rgb(249, 249, 249);
  border: 1px solid rgb(35, 35, 35);
  padding: 10px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.818);
  margin: 10px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 186px;
  transition-duration: 0.4s;

  &:hover {
    background-color: #7a13a3;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
  }
`;

const HelpButton = styled.button<{ quoteHelp: number }>`
  background-color: ${(props) =>
    props.quoteHelp >= 3 ? "#a4a4a4" : "#009f6f"};
  grid-column: span 1;
  color: rgb(249, 249, 249);
  border: 1px solid rgb(35, 35, 35);
  padding: 10px;
  /* box-shadow: 5px 5px rgba(0, 0, 0, 0.818); */
  box-shadow: ${(props) =>
    props.quoteHelp >= 3 ? "" : "5px 5px rgba(0, 0, 0, 0.818)"};
  margin: 10px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 186px;
  transition-duration: 0.4s;

  &:hover {
    background-color: ${(props) =>
    props.quoteHelp >= 3 ? "#a4a4a4" : "#006d4c"};
    box-shadow: ${(props) =>
    props.quoteHelp >= 3 ? "" : "0 2px 4px rgba(0, 0, 0, 0.1)"};
  }
`;

const SignupMessage = styled.div`
  font-size: 1rem;
  color: #191919;
  text-align: center;
  margin-top: 2rem;
`;

// const HelpButton = styled.button<{ quoteHelp: number }>`
//   background-color: ${(props) =>
//     props.quoteHelp >= 3 ? "#a4a4a4" : "#009f6f"};
//   color: #fff;
//   font-size: 1rem;
//   padding: 8px 16px;
//   border: none;
//   border-radius: 3px;
//   width: 186px;
//   cursor: pointer;
//   transition: background-color 0.2s ease;

//   &:hover {
//     background-color: ${(props) =>
//       props.quoteHelp >= 3 ? "#a4a4a4" : "#006d4c"};
//   }
// `;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  @media (max-width: 700px) {
    /* display: block; */
    grid-template-columns: repeat(1, 2fr);
    gap: 10px;
    /* margin: 5px; */
  }
`;

const ArtOfTheDayPage: React.FC = () => {
  const [quote, setQuote] = useState<string>("");
  const [quoteFound, setQuoteFound] = useState(true);
  // let quoteSet = false;
  // const [quoteSet, setQuoteSet] = useState(false);
  const [permission, setPermission] = useState(false);
  const [loading, setLoading] = useState(true);
  const [quoteHelp, setQuoteHelp] = useState(0);
  const [suggestionLoading, setSuggestionLoading] = useState(false);
  const location = useLocation();
  const [isSignedUp, setIsSignedUp] = useState<boolean>(false);
  const [serverError, setServerError] = useState(false);

  useEffect(() => {
    document.title = `NΛSOS Art Of The Day`;
  }, []);


  
  const handleLoading = useCallback(
    (newLoading: boolean) => {
      if (loading !== newLoading) setLoading(newLoading);
    },
    [location, loading]
  );

  useEffect(() => {
    // Check the condition
    if (loading) {
      // Perform the desired action when the condition is met
      console.log("Child's loading prop has changed:", loading);
    }
  }, [loading]);

const today = new Date().toISOString().slice(0, 10); // YYYY-MM-DD format
const imageUrl = `${url}/api/art-of-the-day/today-small/${today}`;


  return (

    <ArtOfTheDayPageContainer>
      <Link to="/art-of-the-day/gallery">
        <GalleryButton>Gallery</GalleryButton>
      </Link>
      <Title>Art of the Day</Title>
      <ArtOfTheDay onLoadingChange={handleLoading} />
      <Description>
        AI generated art. updates every night at 4:50 UTC.
        <br />
        <br />
        the art is created only when someone visits this page. <br />
        whoever visits this page first becomes the author of the art.
      </Description>
    </ArtOfTheDayPageContainer>
  );
};

function CharacterCounter({
  count,
  maxCount,
}: {
  count: number;
  maxCount: number;
}) {
  return (
    <div className="character-counter">
      {count} / {maxCount}
    </div>
  );
}

export default ArtOfTheDayPage;
