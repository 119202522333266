import React from 'react';
import { useSelector } from 'react-redux';
import { useState, setState } from "react";
import eva from "./evabirthday2023.png"


const BirthdayEva = () => {
    // I am assuming your state has a user object.
    // Please adjust this selector as per your state structure.
    const user = useSelector((state) => state.auth);

    if (user?.username === 'ev4ki' || user?.username === 'n450s') {
        return (
            <div>
                <img src={eva} alt="birthday"  style={{ width: '100vw' }}/> 
                <p>Sagapaw!!! Des katw ap to maksilari sou!</p>
            </div>
        );
    } else {
        return null; // Render nothing if user's name is not Nikos
    }
}

export default BirthdayEva;
