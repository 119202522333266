import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useLoadAOTD } from "./useLoad";
import { ArtInfo } from "./useInfo";

// Define spinner animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Other styled-components omitted for brevity...

const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
  width: auto;
  height: auto;
  /* border: 1px solid #ccc; // Add border to visualize the square */
`;

const Spinner = styled.div`
  border: 16px solid #f3f3f3;
  border-radius: 5%;
  border-top: 16px solid #2e2e2e;
  width: 30px;
  height: 30px;
  animation: ${spin} 2s linear infinite;
`;

const ArtContainer = styled.div`
  width: auto;
  height: auto;
  position: relative;
  transition: all 0.3s ease-in-out;

  &:hover {
    /* transform: scale(1.07); */
  }
`;

const ArtImage = styled.img`
  width: 100%;
  height: 100%;
`;

const ArtTitle = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 10px;
  left: 10px;
  /* background: rgba(0,0,0,0.5); */
  color: #fff;
  padding: 10px;
  font-weight: bold;
  font-size: 1.5rem;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  display: ${({ isVisible }) => (isVisible ? "none" : "block")};
`;

const ArtAuthor = styled.div<{ isVisible: boolean }>`
  position: absolute;
  bottom: 10px;
  right: 10px;
  /* background: rgba(0,0,0,0.5); */
  color: #fff;
  padding: 10px;
  font-weight: bold;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  display: ${({ isVisible }) => (isVisible ? "none" : "block")};
`;

const ArtDate = styled.div<{ isVisible: boolean }>`
  position: absolute;
  bottom: 10px;
  left: 10px;
  /* background: rgba(0,0,0,0.5); */
  color: #fff;
  padding: 10px;
  font-weight: bold;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  display: ${({ isVisible }) => (isVisible ? "none" : "block")};
`;

const ArtQuote = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: rgba(0,0,0,0.5); */
  color: #fff;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  display: ${({ isVisible }) => (isVisible ? "none" : "block")};
`;

const Art: React.FC<{ artInfo: ArtInfo }> = ({ artInfo }) => {
  const { artUrl, fetched, loading } = useLoadAOTD(artInfo);
	const [touchCount, setTouchCount] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  // Check if the device is a mobile device or not
  useEffect(() => {
    const checkIfMobile = () => {
      if(window.matchMedia("(max-width: 768px)").matches) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    window.addEventListener('resize', checkIfMobile);
    checkIfMobile();
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);


  const handleClick = () => {
    if(isMobile) {
      // Increase touchCount by 1 when user touches/taps the art on mobile
      // This will toggle the information display
      setTouchCount(prev => prev + 1);
    }
  }

  const [isHovered, setIsHovered] = useState(false);
	if (!artInfo || !fetched) return null;
  if (loading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }
  return (
    <ArtContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
			onClick={handleClick}
    >
      {artUrl && <ArtImage src={artUrl} alt="Art of the day" />}
      <ArtTitle isVisible={(!isMobile && isHovered) || (isMobile && touchCount % 2 !== 0)}>{artInfo.title}</ArtTitle>
      <ArtAuthor isVisible={(!isMobile && isHovered) || (isMobile && touchCount % 2 !== 0)}>by: {artInfo.createdBy}</ArtAuthor>
      <ArtDate isVisible={(!isMobile && isHovered) || (isMobile && touchCount % 2 !== 0) }>
        {artInfo.date.toLocaleDateString()}
      </ArtDate>
      {artInfo.authorQuote && (
        <ArtQuote isVisible={(!isMobile && isHovered) || (isMobile && touchCount % 2 !== 0)}>{artInfo.authorQuote}</ArtQuote>
      )}
    </ArtContainer>
  );
};

export default Art;
