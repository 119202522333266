import { combineReducers } from "redux"

import galleryReducer from "./galleryReducer";
import authReducer from "./authReducer";
import chatReducer from "./chatReducer";

const rootReducer = combineReducers({
	galleries: galleryReducer,
	auth: authReducer,
	chat: chatReducer
})

export default rootReducer