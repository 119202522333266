import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { url } from "../../api";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { saveAs } from 'file-saver';


const QRNotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; 
	background-color: #c8d6ff;
`;


const DownloadButton = styled.button`
  grid-column: span 1;
  color: rgb(249, 249, 249);
  border: 1px solid rgb(35, 35, 35);
  padding: 10px;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.818);
  background-color: #444;
  margin: 10px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 186px;
  transition-duration: 0.4s;

  &:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    background-color: #222;
  }
`;

const QRNotes = () => {
  const downloadPdf = async () => {
    try {
      const response = await axios.get(url + "/api/qrnotes/newsheet", {
        responseType: "blob",
      });

			const link = document.createElement("a");

      const today = new Date();
      const formattedDate = `${today.getFullYear()}-${String(
					today.getMonth() + 1
			).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
			
			const formattedTime = `${String(today.getHours()).padStart(2, "0")}:${String(
					today.getMinutes()
			).padStart(2, "0")}:${String(today.getSeconds()).padStart(2, "0")}`;
			
			const filename = `QRNotesSheet_${formattedDate}_${formattedTime}.pdf`;
		

			saveAs(response.data, filename);
			
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };

  return (
    <QRNotesContainer>
      {/* ... other content ... */}
			<p>Generate a sheet with QR codes to use in the app QRNotes!</p>
      <DownloadButton onClick={downloadPdf}>Download PDF</DownloadButton>
      {/* ... other content ... */}
    </QRNotesContainer>
  );
};

export default QRNotes;
