import React, { useState, useEffect, useRef } from "react";
import "./chatBubble.css";
import ChatDiv from "./chatScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";

const ChatBubble = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const chatBubbleRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        chatBubbleRef.current &&
        !chatBubbleRef.current.contains(event.target)
      ) {
        setIsChatOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [chatBubbleRef]);

  const toggleChat = () => {
    setInitialLoad(false);
    setIsChatOpen(!isChatOpen);
  };

  const getChatDivClassName = () => {
    if (initialLoad) {
      return "chat-div-container chat-div-initial";
    } else {
      return isChatOpen
        ? "chat-div-container chat-div-open"
        : "chat-div-container chat-div-closed";
    }
  };

  return (
    <div className="chat-bubble-container" ref={chatBubbleRef}>
      <div className={getChatDivClassName()}>
        <ChatDiv />
      </div>
      <div className="chat-bubble" onClick={toggleChat}>
        <FontAwesomeIcon icon={faComment} />
      </div>
    </div>
  );
};

export default ChatBubble;
