import React, { ChangeEvent, useState } from 'react';
import axios from 'axios';
import { StoreInfo } from './useInfo';
import { url } from 'src/api';

const EditCard: React.FC<{ storeInfo: StoreInfo }> = ({ storeInfo }) => {
	const [title, setTitle] = useState(storeInfo.title);
	const [creator, setCreator] = useState(storeInfo.creator);
	const [description, setDescription] = useState(storeInfo.description);
	const [price, setPrice] = useState(storeInfo.price as number);
	const [size, setSize] = useState(storeInfo.size);
	const [selected, setSelected] = useState(storeInfo.selected as boolean); // Add state for toggle button
	const [image, setImage] = useState<File | null>(null);
	const [inGallery, setInGallery] = useState(storeInfo.inGallery as boolean);

	const handleSubmit = async () => {
		const formData = new FormData();
		formData.append('title', title);
		formData.append('creator', creator ?? '');
		formData.append('description', description ?? '');
		formData.append('price', price.toString() ?? '');
		formData.append('size', size ?? '');
		formData.append('selected', selected.toString());
		formData.append('inGallery', inGallery.toString());


		if (image) {
			formData.append('image', image);
			try {
				await axios.post(`${url}/api/store/addImage/${storeInfo.id}`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
						"x-auth-token": localStorage.getItem("token")
					},
				});
			} catch (error) {
				console.error('Error uploading image:', error);
			}
		}

		try {
			await axios.patch(
				`${url}/api/store/update/${storeInfo.id}`,
				{
					title,
					creator,
					description,
					price,
					size,
					selected,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token")
					}
				}
			);
			alert('Update successful');
		} catch (error) {
			alert('Update failed');
		}
	};

	const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const file = e.target.files[0];
			setImage(file);
		}
	};

	return (
		<div className='edit-card'>
			<div className="edit-title">

				Edit Store
			</div>
			<div>
				<label>Title:</label>
				<input value={title} onChange={(e) => setTitle(e.target.value)} />
			</div>
			<div>
				<label>Creator:</label>
				<input value={creator} onChange={(e) => setCreator(e.target.value)} />
			</div>
			<div>
				<label>Description:</label>
				<textarea value={description} onChange={(e) => setDescription(e.target.value)} />
			</div>
			<div>
				<label>Price:</label>
				<input value={price} onChange={(e) => setPrice(Number(e.target.value))} />
			</div>
			<div>
				<label>Size:</label>
				<input value={size} onChange={(e) => setSize(e.target.value)} />
			</div>
			<div>
				<label>Selected:</label>
				<input
					type="checkbox"
					checked={selected}
					onChange={() => setSelected(!selected)}
				/>
			</div>
			<div>
				<label>In gallery:</label>
				<input
					type="checkbox"
					checked={inGallery}
					onChange={() => setInGallery(!inGallery)}
				/>
			</div>
			<div>
				<label>Image:</label>
				<input
					type="file"
					onChange={handleImageChange}
				/>
			</div>
			<button onClick={handleSubmit}>Submit</button>
		</div>
	);
};

export default EditCard;
