import React, { useState, useEffect } from "react";
import UserInfo from "./profile-box";
import EditableUserInfo from "./profile-box-edit";
import { url } from "../../api/index.js";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/reducers/types";
import { changeCredentials } from "src/store/actions/authActions";
import { AppDispatch } from "src/store/store";
import PreferencesBox from "./preferences-box";

interface User {
  username: string;
  email: string;
  userEmoji: string;
  role: string;
  dateSigned?: string;
}

const Profile: React.FC = () => {
  const [editing, setEditing] = useState(false);
  const [user, setUser] = useState<User>();
  let authUser = useSelector((state: RootState) => state.auth);

  // console.log(`token user: ${JSON.stringify(authUser)}`)

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    fetchUser();
  }, [authUser]);

  const fetchUser = async () => {
    const token = localStorage.getItem("token");
    const headers: HeadersInit = {
      ...(token ? { "x-auth-token": token } : {}),
    };

    const response = await fetch(`${url}/api/edit/userself/${authUser.username}`, { headers });
    if (response.status !== 200) {
      return;
    }
    const userData = await response.json();
    setUser(userData);
  };

  const handleSubmit = async (updatedUserData: Partial<User>) => {
    dispatch(changeCredentials(updatedUserData));
    await fetchUser();
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  if (!user) {
    return <UserInfo
      username={"..."}
      userEmoji=""
      onAddEmoji={() => { }}
      email={"..."}
      role={"..."}
      dateSigned={"..."}
      onEditButtonClick={() => setEditing(true)}
    />
  }

  return editing ? (

    <EditableUserInfo
      username={user.username}
      email={user.email}
      role={user.role}
      dateSigned={user.dateSigned || "Not available"}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    />

  ) : (
    <UserInfo
      username={user.username}
      email={user.email}
      role={user.role}
      userEmoji={user.userEmoji}
      onAddEmoji={(userEmoji) => handleSubmit({ userEmoji })}
      dateSigned={user.dateSigned || "Not available"}
      onEditButtonClick={() => setEditing(true)}
    />
  );
};

export default Profile;
