import React, { ChangeEvent, useState } from 'react';
import axios from 'axios';
import { url } from 'src/api'; // Adjust this import path as per your setup

interface AddCardProps {
  // Add props if needed
}

const AddCard: React.FC<AddCardProps> = () => {
  // Initialize all state variables
  const [title, setTitle] = useState<string>('');
  const [creator, setCreator] = useState<string | undefined>('');
  const [description, setDescription] = useState<string | undefined>('');
  const [dateMade, setDateMade] = useState<Date | undefined>();
  const [image, setImage] = useState<File | null>(null);
  const [addedBy, setAddedBy] = useState<string | undefined>('');
  const [price, setPrice] = useState<number | undefined>();
  const [stock, setStock] = useState<number | undefined>();
  const [size, setSize] = useState<string | undefined>('');
  const [merchType, setMerchType] = useState<string | undefined>('');
  const [productNumber, setProductNumber] = useState<number | undefined>();
  const [selected, setSelected] = useState<boolean>(false);
  const [inGallery, setInGallery] = useState<boolean>(false);

  // Handle form submission
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('creator', creator ?? '');
    formData.append('description', description ?? '');
    formData.append('dateMade', dateMade?.toISOString() ?? '');
    formData.append('addedBy', addedBy ?? '');
    formData.append('price', price?.toString() ?? '');
    formData.append('stock', stock?.toString() ?? '');
    formData.append('size', size ?? '');
    formData.append('merchType', merchType ?? '');
    formData.append('productNumber', productNumber?.toString() ?? '');
    formData.append('selected', selected.toString());
    formData.append('inGallery', inGallery.toString());

    if (image) {
		formData.append('image', image);
	  }
	
	  try {
		await axios.post(`${url}/api/store/upload`, formData, {
		  headers: {
			'Content-Type': 'multipart/form-data',
			'x-auth-token': localStorage.getItem('token'),
		  },
		});
		alert('Add successful');
	  } catch (error) {
		console.error('Error:', error);
		alert('Add failed');
	  }
	};

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setImage(file);
    }
  };

  return (
	<div className="add-card">
	  <div className="add-title">
		Add New Item
	  </div>
	  <div>
		<label>Title:</label>
		<input value={title} onChange={(e) => setTitle(e.target.value)} />
	  </div>
	  <div>
		<label>Creator:</label>
		<input value={creator} onChange={(e) => setCreator(e.target.value)} />
	  </div>
	  <div>
		<label>Description:</label>
		<textarea value={description} onChange={(e) => setDescription(e.target.value)} />
	  </div>
	  <div>
		<label>Date Made:</label>
		<input type="date" onChange={(e) => setDateMade(new Date(e.target.value))} />
	  </div>
	  <div>
		<label>Added By:</label>
		<input value={addedBy} onChange={(e) => setAddedBy(e.target.value)} />
	  </div>
	  <div>
		<label>Price:</label>
		<input value={price} onChange={(e) => setPrice(Number(e.target.value))} />
	  </div>
	  <div>
		<label>Stock:</label>
		<input value={stock} onChange={(e) => setStock(Number(e.target.value))} />
	  </div>
	  <div>
		<label>Size:</label>
		<input value={size} onChange={(e) => setSize(e.target.value)} />
	  </div>
	  <div>
		<label>Merch Type:</label>
		<input value={merchType} onChange={(e) => setMerchType(e.target.value)} />
	  </div>
	  <div>
		<label>Product Number:</label>
		<input value={productNumber} onChange={(e) => setProductNumber(Number(e.target.value))} />
	  </div>
	  <div>
		<label>Selected:</label>
		<input type="checkbox" checked={selected} onChange={() => setSelected(!selected)} />
	  </div>
	  <div>
		<label>In gallery:</label>
		<input type="checkbox" checked={inGallery} onChange={() => setInGallery(!inGallery)} />
	  </div>
	  <div>
		<label>Image:</label>
		<input type="file" onChange={handleImageChange} />
	  </div>
	  <button onClick={handleSubmit}>Add Item</button>
	</div>
  );  
};

export default AddCard;
