// import ReactGA from 'react-ga';

// export const initGA = () => {
//   ReactGA.initialize('G-ETB71GSBPS'); // Replace with your Google Analytics tracking ID
// };

// export const logPageView = () => {
//   ReactGA.set({ page: window.location.pathname });
//   ReactGA.pageview(window.location.pathname);
// };

// export const logEvent = (category = '', action = '') => {
//   if (category && action) {
//     ReactGA.event({ category, action });
//   }
// };

// export const logException = (description = '', fatal = false) => {
//   if (description) {
//     ReactGA.exception({ description, fatal });
//   }
// };

// export const logPageView = () => {
//   window.gtag('event', 'page_view', {
//     page_location: window.location.href,
//     page_path: window.location.pathname,
//   });
// };

// export const logAOTDView = (title, date) => {
//   window.gtag('event', 'page_view', {
//     page_location: window.location.href,
//     page_path: window.location.pathname,
//     title: title, // Custom parameter for content title
//   });
// };

// export const setUser = (username) => {
//   window.gtag('config', 'G-ETB71GSBPS', {
//     username: username,
//   });
// };


// export const logEvent = (action, params) => {
//   window.gtag('event', action, params);
// };

// export const logException = (description, fatal) => {
//   window.gtag('event', 'exception', { description, fatal });
// };

import ReactGA from "react-ga4";

export const initializeGA = () => {
  ReactGA.initialize("G-ETB71GSBPS"); // Replace with your actual GA4 Measurement ID
};

export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};

export const logAOTDView = (title, date) => {
  ReactGA.event({
    category: "AOTD",
    action: "View",
    label: title,
  });
};

export const setUser = (username) => {
  ReactGA.set({ username: username });
};

export const logEvent = (action, params) => {
  ReactGA.event({
    category: "Custom Event",
    action: action,
    ...params,
  });
};



