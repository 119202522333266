import {toast} from "react-toastify"

const galleryReducer = (state = [], action) =>{
	switch(action.type){
		case "GET_ARTS":

			return action.galleries.data
		case "ADD_ART":
			console.log(action)
			toast.success("Art added successfully", {
				position: toast.POSITION.BOTTOM_RIGHT
			})
			return [action.gallery.data, ...state]
		default:
			return state
	}

}

export default galleryReducer