import axios from "axios";
import React, { ReactNode, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

interface ModalProps {
    onClose: () => void;
    onConfirm: () => void;
    children: ReactNode;
  }

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  width: auto;  // Adjust width as needed
  max-width: 500px;  // Or any other suitable value
  margin: auto;
  margin: 0 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Button = styled.button`
  margin-left: 10px;
  padding: 5px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  background-color: #007bff;
  color: white;

  &:hover {
    background-color: #0056b3;
  }
`;

const VerificationInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;


const Modal: React.FC<ModalProps> = ({ onClose, onConfirm, children }) => {
  const [verificationCode, setVerificationCode] = useState('');
  


  const handleVerifyCode = async () => {
    // Call the backend API to verify the code
    try {
      const url = 'http://localhost:3000'; // Replace with your server's URL
      const response = await axios.post(url + '/api/verify/verify-code', {
        code: verificationCode,
        // Add any other required fields
      }, {
        headers: {
          'x-auth-token': localStorage.getItem('token'), // Adjust as needed
        },
      });

      if (response.status === 200) {
        // Handle successful verification
        onClose(); // Close the modal
        // Further actions like showing a success message
        // show a toast notification
        // toast("Verification email sent. Please check your inbox.");
        toast("Verification successful");
      } else {
        // Handle unsuccessful verification
        // Show an error message
      }
    } catch (error) {
      console.error('Error verifying code:', error);
      // Handle error
    }
  };

  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {children}
        <VerificationInput
          type="text"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          placeholder="Enter verification code"
        />
        <ButtonGroup>
          <Button onClick={handleVerifyCode}>Verify</Button>
          <Button onClick={onClose}>Cancel</Button>
        </ButtonGroup>
      </ModalContent>
    </ModalBackdrop>
  );
};

  


export default Modal;

