import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {addGallery} from "../../store/actions/galleryActions"

function AddArt() {
  // const [title, setTitle] = useState('');
  // const [description, setDescription] = useState('');
  // const [dateMade, setDateMade] = useState('');
  // const [photo, setPhoto] = useState(null);

  const [art, setArt] = useState({
		title: "",
		creator: "",
		description: "",
		toShow: false,
		image: null,
		dateMade: "",
  })

  const dispatch = useDispatch()

  const handleTitleChange = (event) => {
    setArt(previousState =>{
      return {...previousState, title: event.target.value}
    } );
  };

  const handleDescriptionChange = (event) => {
    setArt(previousState =>{
      return {...previousState, description: event.target.value}
    } );
  };

  const handleCreatorChange = (event) => {
    setArt(previousState =>{
      return {...previousState, creator: event.target.value}
    } );
  };

  const handleDateMadeChange = (event) => {
    setArt(previousState =>{
      return {...previousState, dateMade: event.target.value}
    } );
  };

  const handlePhotoUpload = (event) => {
    setArt(previousState =>{
      return {...previousState, image: event.target.files[0]}
    } );
    // setimage(event.target.files[0]);
  };

  // const handleDragOver = (event) => {
  //   event.preventDefault();

  // };

  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   setArt(previousState =>{
  //     return {...previousState, image: event.dataTransfer.files[0]}
  //   } );
  //   // setPhoto(event.dataTransfer.files[0]);
  // };

  const handleSubmit = (event) => {
		event.preventDefault()
    let artSend = art
    // setArt(previousState =>{
    //   return {...previousState, dateMade : new Date(art.dateMade)}
    // } );
    artSend.dateMade = new Date(art.dateMade)
    
    console.log(artSend)

    const formData = new FormData();
    formData.append("title", art.title);
    formData.append("description", art.description);
    formData.append("creator", art.creator);
    formData.append("dateMade", art.dateMade);
    formData.append("toShow", art.toShow);
    formData.append("image", art.image);

    // console.log(event.target.value)
    dispatch(addGallery(formData))
  }

  return (
    <AddContainer>

		Add item
      <form noValidate encType="multipart/form-data" autoComplete = "off" onSubmit={handleSubmit}>
        <label>
          Title:
          <StyledInput type="text" value={art.title} onChange={handleTitleChange} />
        </label>
				<br/>
				<label>
          Creator:
          <StyledInput defaultValue={"nassos"} type="text" value={art.creator} onChange={handleCreatorChange} />
        </label>
        <br />
        <label>
          Description:
          <textarea style={{width: "400px", height: "200px" }} value={art.description} onChange={handleDescriptionChange} />
        </label>
        <br />
        <label>
          to show:
          <input type="checkbox" value={art.toShow} onChange={()=> {setArt( previousState => {return {...previousState, toShow: !art.toShow}})}}/>
        </label>
        <br />
        <label>
          Date Made:
          <StyledInput type="date" value={art.dateMade} onChange={handleDateMadeChange} />
        </label>
        <br />
        <label>
          Photo:
          <StyledInput type="file" onChange={handlePhotoUpload} />
        </label>
        <br />
        {/* <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          style={{ border: '1px solid black', width: '400px', height: '300px', alignContent: "center" }}
        >
          Drag and drop photo here
        </div> */}
        <br />
        <button type="submit">Submit</button>
      </form>
    </AddContainer>
  );
}

const AddContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 40px;
  /* justify-content: flex-end; */
  height: 100vh;
  /* align-items: ; */
  /* justify-items: center; */
  background-color: #d3feff;
  text-emphasis-color: #223126;
  /* align-content: center; */
	/* text-align: center; */
  @media (max-width: 600px)  {
    padding: 25px;
  }

`;

const StyledInput = styled.input`
	::-webkit-input-placeholder {
    color: gray;
  }
  :-moz-placeholder {
    color: gray;
  }
  ::-moz-placeholder {
    color: gray;
  }
  :-ms-input-placeholder {
    color: gray;
  }
  zoom: 1;
  font-size: 1rem;
	border: 1px solid;
	padding: 5px;
  width: 35%;
	box-shadow: 2px 2px 4px rgba(236, 236, 236, 1);
	margin:  10px;
  transition: 0.2s;
	&:hover {
    /* background-color: ${props => (props.active ? '#7edef1' : '#a1f4ff')}; */
    padding: 6px;
		/* box-shadow: 4px 4px 4px rgba(236, 236, 236, 1); */
  }
  @media (max-width: 600px)  {
    width: 90%;
  }

`;

const StyledSubmit = styled.input`
	border: 1px solid;
	padding: 5px;
	/* color: #18d3fd; */
	background-color:#a4e8fb;
	box-shadow: 2px 2px 4px rgba(236, 236, 236, 1);
	margin:  10px;
	text-align: center;
  font-size: 0.8rem;
	/* background-color: ${props => (props.active ? '#9beece' : 'white')}; */
  transition: padding 0.2s;
  &:hover {
    background-color: ${props => (props.active ? '#7ecdf1' : '#bce7fa')};
    padding: 7px;
  }
  @media (max-width: 600px)  {
    height: 50px;
    width: 30%;
  }
`;

export default AddArt;
