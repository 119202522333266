import React from "react";
import styles from "./profile-box.module.css";
import EmojiInput from "./emojiInput"; // Import the new EmojiInput component

interface UserInfoProps {
  username: string;
  email: string;
  role: string;
  dateSigned: string;
  userEmoji: string;
  onEditButtonClick: () => void;
  onAddEmoji: (userEmoji: string) => void;
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  return date.toUTCString();
}

const UserInfo: React.FC<UserInfoProps> = ({
  username,
  email,
  role,
  dateSigned,
  userEmoji,
  onEditButtonClick,
  onAddEmoji,
}) => {
  return (
    <div className={styles.userInfo}>
      <label className={styles.infoBox}>
        <strong>username:</strong> {userEmoji && <span>{userEmoji}</span>} {username}
      </label>
      <label className={styles.infoBox}>
        <strong>email:</strong> {email}
      </label>
      <label className={styles.infoBox}>
        <strong>role:</strong> {role}
      </label>
      <label className={styles.infoBox}>
        <strong>date signed:</strong> {formatDate(dateSigned)}
      </label>
      <button className={styles.editButton} onClick={onEditButtonClick}>
        Edit
      </button>
      {(userEmoji === "" || userEmoji === undefined) && (
        <EmojiInput onEmojiSubmit={onAddEmoji} />
      )}
    </div>
  );
};

export default UserInfo;
