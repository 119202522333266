import React from "react";
import { useLoadInfo } from "./useInfo";
import Art from "./Art";
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';


const ArtGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  /* margin: 15 auto; */
  padding: 10px;
  align-items: center;
  gap: 5px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 85%;
    margin: 0 auto;
  }
`;

const Title = styled.div`
  font-size: 2rem;
  color: #222;
  text-align: center;
  font-weight: 600;
  padding: 10px;
`;

const LazyArt: React.FC<{ artInfo: any }> = ({ artInfo }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
    rootMargin: '300px 0px',
  });

  return (
    <div ref={ref} style={{ minHeight: '350px' }}>
      {inView ? <Art artInfo={artInfo} /> : null}
    </div>
  );
};


const ArtGrid: React.FC<{}> = () => {
  const { artInfos } = useLoadInfo();

  return (
	<div style={{
		// background: 'linear-gradient(to bottom, hsl(0, 81.15942028985505%, 72.94117647058823%), hsl(60, 81.95488721804509%, 73.92156862745098%), hsl(120, 84.72222222222227%, 71.76470588235294%), hsl(180, 82.85714285714285%, 72.54901960784314%), hsl(240, 86.20689655172417%, 71.56862745098039%), hsl(300, 84.39716312056743%, 72.35294117647058%))',
		minHeight: '100vh', // Set the height to fill the screen
		height: 'auto', // Prevent the height from being larger than the screen
	}}>
	<Title>Your AOTD Gallery</Title>
	<ArtGridContainer>
      {artInfos.map((artInfo, index) => (
        <LazyArt key={index} artInfo={artInfo} />
      ))}
    </ArtGridContainer>
	</div>
    
  );
};

export default ArtGrid;
