import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import logo from '../../assets/logos/nassos.svg';
import logo2 from '../../assets/logos/bolt.png';
import nassos_gour from '../../assets/photos/nassos_gour450.jpeg';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { toast } from 'react-toastify'

import { signIn } from "../../store/actions/authActions";
import { useDispatch, useSelector } from 'react-redux';
import { logPageView } from '../../analytics/GoogleAnalytics';
import { useNavigate } from 'react-router-dom';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 40px;
  /* justify-content: flex-end; */
  height: 100vh;
  background-color: #fdbe7c;
  text-emphasis-color: #223126;
  @media (max-width: 600px)  {
    padding: 25px;
  }

`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  /* justify-content: flex-end; */
  height: inherit;
  background-color: #fdbe7c;
  text-emphasis-color: #223126;
`;

const StyledImg = styled.img`
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 50%;
  margin-top: 10px;
  margin-left: 20px;
  opacity: 0.2;
  /* z-index: 9999; */
  /* position: absolute; */

`;

const BackButton = styled.div`
  display: block;
  /* padding: 8px 16px; */
  font-size: 20px;
  color: #000000;
  text-decoration: none;
  transition: font-size 0.1s;
  &:hover {
    color: #555;
    font-size: 22px;
  }
`;

const StyledForm = styled.form`
	/* border: 1px solid; */
	/* padding: 10px; */
	/* box-shadow: 5px 10px rgba(0, 0, 0, 0.7); */
	/* margin:  20px; */
	/* text-align: left; */
`;

const StyledInput = styled.input`
	::-webkit-input-placeholder {
    color: gray;
  }
  :-moz-placeholder {
    color: gray;
  }
  ::-moz-placeholder {
    color: gray;
  }
  :-ms-input-placeholder {
    color: gray;
  }
  zoom: 1;
  font-size: 1rem;
	border: 1px solid;
	padding: 5px;
  width: 20rem;
	box-shadow: inset 2px 2px 2px rgba(53, 53, 53, 0.401);
	margin:  10px;
  transition: 0.2s;
	&:hover {
    /* background-color: ${props => (props.active ? '#f1c17e' : '#f7dc70')}; */
    padding: 7px;
		/* box-shadow: 4px 4px 4px rgba(236, 236, 236, 1); */
  }
  @media (max-width: 600px)  {
    width: 85%;
  }

`;

const StyledSubmit = styled.input`
	border: 1px solid;
	padding: 5px;
	/* color: #18d3fd; */
	background-color:#f4ab47;
	box-shadow: 2px 2px 4px rgba(46, 45, 45, 0.365);
	margin:  10px;
	text-align: center;
	/* background-color: ${props => (props.active ? '#9beece' : 'white')}; */
  transition: padding 0.2s;
  &:hover {
    background-color: ${props => (props.active ? '#f1c17e' : '#f7dc70')};
    padding: 5px;
  }
  @media (max-width: 600px)  {
    height: 20%;
    width: 30%;
  }
`;

const FullContainer = styled.div`
	display: grid;
	grid-template-columns: 1.5fr 1fr;
	/* grid-column-gap: 10px; */
  @media (max-width: 600px)  {
    display: flex;
    flex-direction: column;
  }
`;

const BoxDiv = styled.div`
	border: 1px solid;
	padding: 10px;
	box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
	margin:  20px;
`;

const H1Style = styled.h1`
	float: right;
`;

const ErrorMessage = styled.p`
    color: #e40000;
    font-size: 0%.8;
    margin: 0 10px;
`;

const LoginPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const { token, error } = useSelector(state => state.auth);

  const [logError, setLogError] = useState(null);

  useEffect(() => {
    document.title = 'NΛSOS Log In'
    logPageView();
  }, []);

  useEffect(() => {
    if (error) {
      setLogError(error);
    }
  }, [error]);

  useEffect(() => {
    if (token) {
      // navigete to home
      navigate('/');
    }
  }, [token, navigate]);


  // state to hold input values
  const [user, setUser] = useState({
    username: '',
    password: ''
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  // const [error, setError] = useState(null);

  // handle input change
  const handleChange = (event) => {
    event.preventDefault()
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
  }

  const handleUsernameChange = (event) => {
    event.preventDefault()
    setUser({
      ...user,
      [event.target.name]: event.target
    });
    let newUsername = event.target.value;
    newUsername = newUsername.toLowerCase().replace(/\s+/g, "");
    if (newUsername.length >= 2) {
      let firstChar = newUsername[0];
      let lastChar = newUsername[newUsername.length - 1];
      newUsername = firstChar + newUsername.slice(1, -1).replace(/a/gi, "4").replace(/s/gi, "5").replace(/o/gi, "0") + lastChar;
    }
    setUser({ ...user, username: newUsername });
  }

  // handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      dispatch(signIn(user));
      // if its correct and user is logged in then go to home
      if (token) {
        navigate('/');
      }
    } catch (error) {
      // Handle errors
      // setError(error.message);
    }
  }

  const goBack = () => {
    setLogError(null);
    navigate(-1); // Navigates back to the previous page
  };

  const goToPreviousPath = () => {
    // if the page is not login, register, or forgot-pw or reset-pw then go back to previous path
    if (navigate.location.state && navigate.location.state.from) {
      navigate(navigate.location.state.from);
    } else {
      navigate('/');
    }
  }


  return (
    <FullContainer>

      <LoginContainer>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <BackButton onClick={goBack}>
          <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
        </BackButton>
        <H1Style>please login</H1Style>
        <StyledForm onSubmit={handleSubmit}>
          username (450 autoformat)
          <br />
          <StyledInput type="username" name="username" placeholder='username' value={user.username} onChange={handleUsernameChange} required />
          <br />
          password
          <br />
          <StyledInput type={passwordVisible ? "text" : "password"} name="password" placeholder='password' value={user.password} onChange={handleChange} required />
          <i className="fa fa-eye" aria-hidden="true" onClick={() => setPasswordVisible(!passwordVisible)}></i>
          <br />

          <ErrorMessage>{logError}</ErrorMessage>
          <StyledSubmit type="submit" value="Log in" />
          <div style={{ fontSize: "0.9rem", fontWeight: "600", color: "#525252", margin: "4px" }}>you don't have an account? <Link to={"/register"}>signup here</Link></div>
          <div style={{ fontSize: "0.9rem", fontWeight: "600", color: "#525252", margin: "4px" }}>forgot your password? <Link to={"/forgot-pw"}>reset password here</Link></div>

        </StyledForm>
      </LoginContainer>

      <ImageContainer>
        <StyledImg src={nassos_gour} className="image-nassos" alt="logo" />
      </ImageContainer>
    </FullContainer>

  );
}


export default LoginPage;
