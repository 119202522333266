import { React, useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownDivider } from 'semantic-ui-react';
// import './site_header.css';
import styled from 'styled-components';
import logo from '../../assets/logos/nassos.svg';
import logo2 from '../../assets/logos/bolt.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTelegram, faTelegramPlane, faXTwitter } from '@fortawesome/free-brands-svg-icons';


const FooterUl = styled.ul`
  list-style: none;
  display: flex;
  padding: 0px;

  @media (max-width: 600px) {
    /* margin-left: 100px; */
    display: block;
  }
`;

const StyledLogo = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 120px;
  margin-top: 10px;
  margin-left: 20px;
  z-index: 9999;
  position: absolute;

  @media (max-width: 600px) {
    width: 40%;
    margin-top: 50px;
  }
`;

const Block = styled.div`
  margin: 10px;
  text-shadow: 3px;
  display: flex;
  align-items: center;
  font-size: 16px; /* Same as the text */
  color: ${props => (props.active ? 'black' : '#3f3f3fff')};
  &:hover {
    color: black;
  }
  &:active {
    color: #ff4be1;
  }
  &:focus {
    color: black;
  }
`;

const FooterContainer = styled.footer`
	padding: 20px;
	background-color: #ffffff;
	border-top: 10px solid rgba(0,0,0,0.7) ;
`;

const LinkStyle = styled(Link)`
  color: ${props => (props.active ? "black" : "#3f3f3fff")};
  text-decoration: none;
  &:hover {
    color: black;
  }
  &:active {
    color: #ff4be1;
  }
  &:focus {
        color: black;
  }
`;

const Footer = () => {

  const [activeIndex, setActiveIndex] = useState(0);
  console.log(useState(2))
  const blocks = [
    { name: 'about', link: '/about' },
    { name: 'contact', link: '/contact' },
  ];

  return (
    <div className="footer-container">
      <FooterContainer>
        <nav>
          <FooterUl>
            {blocks.map(({ name, link }, index) => (
              <LinkStyle key={name} to={link}>
                <Block
                  active={index === activeIndex}
                  onClick={() => setActiveIndex(index)}
                >
                  {name}
                </Block>
              </LinkStyle>
            ))}
            <a href="https://x.com/n450s" target="_blank" rel="noopener noreferrer">
              <Block style={{ "marginLeft": "20px" }}>
                <FontAwesomeIcon icon={faXTwitter} size="lg" />
              </Block>
            </a>
            <a href="https://t.me/+CxzdB8kP_3RlZGQ0" target="_blank" rel="noopener noreferrer">
              <Block style={{ "marginLeft": "20px" }}>
                <FontAwesomeIcon icon={faTelegramPlane} size="lg" />
              </Block>
            </a>
          </FooterUl>
          <div className="tmarks" >
            <p style={{ "fontSize": "9px", "margin": "1px", "color": "gray" }}> site owner: owner of n450s.com domain. all rights.</p>
          </div>
        </nav>
      </FooterContainer>

    </div>
  );
}

export default Footer;
