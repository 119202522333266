import React, { useEffect } from 'react';
import { logPageView } from '../../analytics/GoogleAnalytics';


function ErrorPage() {
	useEffect(() => {
		document.title = 'NΛSOS Not Found'
    logPageView();
  }, []);
	return (
		<div>
			PAGE NOT FOUND. 404
		</div>
	)
}

export default ErrorPage