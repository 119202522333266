const initialState = {
  chatID: null,
  messages: [],
  pending: false,
  offensive: false
  // Add other properties here as needed
};

const chatReducer = (state = initialState, action) => {
  //   console.log(`ACTION messages: ${JSON.stringify(action.messages)}`);
  switch (action.type) {
    case "GET_CHAT":
      return {
        ...state,
        messages: action.data.messages,
        offensive: false,
        // chatID: action.chatID,
      };
    case "OFFENSIVE":
      return {
        ...state,
        offensive: true,
        // chatID: action.chatID,
      };
    case "SEND_MESSAGE":
      return {
        ...state,
        messages: action.data.messages,
        pending: false,
        offensive: false,
      };
    case "PENDING_RESPONSE":
      return {
        ...state,
        // messages: state.messages.map((message) =>
        //   message.pending ? action.message : message
        // ),
        pending: true,
      };
    default:
      return state;
  }
};

export default chatReducer;
