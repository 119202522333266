import styled, { keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(0.95); }
  100% { transform: scale(1); }
`;

export const LoadingImage = styled.img`
  /* max-width: 60%; */
  animation: ${pulseAnimation} 1s infinite;
`;

export const CardContainer = styled.div`
  /* border: 1px solid #e0e0e0; */
  padding: 20px;
  max-width: 50vw;
  margin: 0 auto;
`;

export const CardTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

export const StoreImage = styled.img`
  /* width: 60vw; */
  height: 50vh;
`;

export const ImageContainer = styled.div`
  position: relative;
  grid-row: 1;
`;

export const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #ffffffc1;
  font-size: 3rem;
  cursor: pointer;
  z-index: 1;
  /* box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2); */
  text-shadow: 0px 3px 10px rgb(254, 254, 254);
  font-weight: 100;

`;

export const Dot = styled.span`
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #ffffffb3;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease;
  box-shadow: 0 0 2px #3f3f3fd6;

  &.active {
    background-color: #3a3a3a;
    /* border: 1px solid #d0d0d0; */
    box-shadow: 0 0 5px #e5e5e5;
  }
`;


export const PrevButton = styled(NavigationButton)`
  left: 10px;
`;

export const NextButton = styled(NavigationButton)`
  right: 10px;
`;

export const Creator = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
`;

export const Description = styled.p`
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const Price = styled.span`
  font-weight: bold;
  font-size: 24px;
`;

export const Size = styled.span`
  margin-right: 20px;
`;

export const BuyButton = styled.button`
  background-color: #000;
  color: #FFF;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
`;

export const HeartIcon = styled.span`
  /* Add styles for the heart icon here */

`;





