const server_url = process.env.REACT_APP_SERVER_URL;
export const url = server_url;

export const setHeaders = (extraHeaders) => {
  const token = localStorage.getItem("token");

  if (!token || token === "") {
    return {
      headers: {
        ...extraHeaders,
      },
    };
  } else {
    return {
      headers: {
        ...extraHeaders,
        "x-auth-token": localStorage.getItem("token"),
      },
    };
  }
};
