// import React from "react";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import "./gallery_item.css";

const GalleryItem = (props) => {
  const date = deteFormat(props.dateMade);
  const [hover, setHover] = useState(false);
  const handleHover = () => {
    setHover(!hover);
  };

  return (
    <Container>
      <Frame>
        <DateStyle>{date}</DateStyle>
        <CreatorStyle>by {props.creator}</CreatorStyle>
        <Img
          src={props.picture}
          alt={props.title}
          onClick={handleHover}
          hover={hover}
        />
        <TitleContainer>
          <Title>{props.title}</Title>
          {/* {<Description>{props.description}</Description>} */}
          {/* {hover && <Description>{props.description}</Description>} */}
        </TitleContainer>
      </Frame>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  z-index: 0;
`;

const Frame = styled.div`
  justify-items: center;
  display: inline-block;
  place-self: center;
  width: 17rem;
  height: 23rem;
  min-width: 5rem;
  max-width: 20rem;

  background: #ffffff;
  place-self: center;
  perspective: 1000px;

  transform-style: preserve-3d;
  transition: all 0.3s ease;

  border: 6px groove #7c7c7c;
  box-shadow: 5px 8px 2px #241f1f65;
  -webkit-border-radius: 8px;
  border-radius: 8px;

  &:hover {
    background: rgb(255, 255, 255);
    transform: scale(1.05);
    z-index: 90;
    box-shadow: 7px 11px 6px 6px #241f1f65;
    border: 6.5px groove #b522ff;
    /* max-height: auto; */
  }

  @media screen and (max-width: 600px) {
    /* width: auto; */
    max-width: 90vw;
    max-height: 80vh;
  }
`;

const Img = styled.img`
  position: relative;
  align-self: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin-top: 20px;
  object-fit: cover;

  max-height: 60%;
  max-width: 90%;

  transition: all 0.3s ease;
  box-shadow: 3px 5px 1px #00000073;
  z-index: 95;

  ${(props) =>
    props.hover &&
    `
		@media screen and (max-width: 600px) {
			z-index: 3;
			transform: scale(1.17);
			box-shadow: 5px 8px 8px 4px #241f1f65;
			object-fit: cover;
			max-width: 100%;
			max-height: 100%;
		}
  `}
  &:hover {
    @media screen and (min-width: 600px) {
      z-index: 3;
      transform: scale(1.17);
      box-shadow: 5px 8px 8px 4px #241f1f65;
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const Title = styled.div`
  padding: 10px;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  position: absolute;
  bottom: 80%;
  z-index: 0;

  ${Container}:hover & {
    z-index: -1;
  }
`;

const Description = styled.div`
  padding: 10px;
  text-align: left;
  /* font-size: 1.5rem; */
  display: none;
  position: absolute;
  font-size: 0.7rem;
  margin-top: -80px;
  padding-bottom: 0;
  padding-top: 0;
  padding-right: auto;
  padding-left: auto;

  /* border: 4px solid #18c5d5; */
  /* background-color: #9a9a9a56;
	-webkit-border-radius: 8px;
	border-radius: 8px; */
  float: inline-start;

  ${Frame}:hover & {
    display: block;
    object-fit: contain;
    z-index: 1;
  }
`;

const TitleContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  /* background-color: rgba(255, 255, 255, 0.8); */
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  /* padding: 10px; */
`;

const DateStyle = styled.div`
  display: inline-block;
  padding-left: 8px;
  padding-top: 8px;
  font-weight: 600;
  font-size: 12px;
  /* width: 50%; */
  /* float: left; */
  margin-left: auto;
  margin-right: 0;

  justify-content: start;
  overflow: hidden;
`;

const CreatorStyle = styled.div`
  display: inline-block;
  padding-right: 8px;
  padding-top: 8px;
  font-weight: 600;
  font-size: 12px;
  /* width: -50%; */
  /* margin-right: auto; 
	margin-left: 0; */
  float: right;
  justify-content: end;
  overflow: hidden;
`;

function deteFormat(dateF) {
  let date = new Date(dateF);
  let month = date.toLocaleString("default", { month: "short" }).toLowerCase();
  let day = date.getDate();
  let year = date.getFullYear().toString().slice(-2);
  // Convert middle letter of month to 4, 5, or 0 if needed
  if (["a", "s", "o"].includes(month[1])) {
    month =
      month[0] + (month[1] === "a" ? 4 : month[1] === "s" ? 5 : 0) + month[2];
  }
  return `${day}_${month}_${year}`; // Example output: "4_aug_21"
}

export default GalleryItem;
