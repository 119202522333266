import React from 'react';
import { RecipeInfo } from '../loaders/useInfo';
// import '../styles/recipe.css'; 
import '../styles/recipe_make.css'; 
import { url as apiUrl } from '../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

export type RecipeMake = {
	userId: string;
	author: string;
	comment: string;
	date: Date;
	image: string;
}

const RecipeMakeCard: React.FC<{recipeInfo: RecipeInfo, recipeMake: RecipeMake }> = ({ recipeInfo, recipeMake }) => {
	return (
		<div className='im_res_div_make'>
			<div className="recipe-make-user-small">{recipeMake.author}</div>
			<img src={`${apiUrl}/api/recipes/images/${recipeInfo.id}/${recipeMake.image}`} alt={recipeMake.author} className="recipe-make-thumbnail" />

			<div className="recipe-make-content">
				<div>
					
					<div className="recipe-make-comment-small">
						{recipeMake.comment}
					</div>
				</div>
				{/* <div className="recipe-make-footer">
					<div>Difficulty: <strong> {`${recipeMake.difficulty}/10`} </strong></div> 
					<div>Time: <strong> {`${recipeMake.time}`} </strong></div> 
				</div> */}
			</div>

		</div>
	);
};


export default RecipeMakeCard;
