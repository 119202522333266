import React from 'react';
import { RecipeInfo } from '../loaders/useInfo'; // Adjust import path as needed
import '../styles/recipe.css'; // Adjust import path as needed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const RecipeItemSmall: React.FC<{ recipeInfo: RecipeInfo, onSelect: () => void }> = ({ recipeInfo, onSelect }) => {
  return (
    <div className='im_res_div' onClick={onSelect}>
      {recipeInfo.images && recipeInfo.images.length > 0 && (
        <img src={recipeInfo.images[0]} alt={recipeInfo.title} className="recipe-thumbnail" />
      )}


      <div className="recipe-content">
        <div>
          <div className="recipe-title-small">{recipeInfo.title}</div>
          <div className="recipe-description-small">
            {recipeInfo.description.length > 100
              ? recipeInfo.description.substring(0, 100) + '...'
              : recipeInfo.description}
          </div>
        </div>
        <div className="recipe-footer">
          <div>Difficulty: <strong> {`${recipeInfo.difficulty}/10`} </strong></div> {/* Adjust as necessary */}
           <div>Time: <strong> {`${recipeInfo.time}`} </strong></div> {/* Adjust as necessary */}
        </div>
      </div>

    </div>
  );
};


export default RecipeItemSmall;
