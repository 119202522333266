import axios from "axios";
import React, { useState, useEffect } from "react";
import { url as apiUrl } from "../../api";

export type ArtInfo = {
  title: string;
  createdBy: string;
  authorQuote: string;
  quoteHelp: number;
  filename: string;
};

export const useLoadAOTD = (
  art_info: ArtInfo ,
) => {
  const url = apiUrl as string; // Add a type assertion here
  const [artUrl, setArtUrl] = useState<string | null>(null);
  const [fetched, setFetched] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchArt = async (filename: string) => {

    if (!fetched) {
      setLoading(true);
      try {
        const response = await axios.get(
          url + `/api/art-of-the-day/image-small/${filename}`,
          {
            responseType: "blob",
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );

        if (response.status === 200) {
          const imageUrl = URL.createObjectURL(response.data);
          setArtUrl(imageUrl);
          setFetched(true);
          setLoading(false);
        } else {
          console.error("Error fetching Art of the Day:", response.status);
        }
      } catch (error) {
        console.error("Error fetching Art of the Day:", error);
      }
    }
  };


  useEffect(() => {
    fetchArt(art_info.filename || "");
  }, [url]);

  return { artUrl, fetched , loading};
};
