import axios from "axios"
import { useSelector } from "react-redux"
import {url , setHeaders} from "../../api"
import {toast} from "react-toastify"

export const getGallery = () => {
	return (dispatch, getState) => {
		const statee = getState()
		console.log(`${url}/api/gallery`)
		toast.promise(axios
		.get(`${url}/api/gallery`, setHeaders())
		.then(galleries => {
			dispatch({
				type: "GET_ARTS",
				galleries
			})
		})
		.catch( error => {
			console.log(error)
			toast.error('cannot fetch gallery',{
				position: toast.POSITION.BOTTOM_RIGHT
			})
		})
		,{
			pending: 'getting the gallery',
			error: 'error getting the gallery'
		}, {position:"bottom-left"})
	}
}

// export const getArt = () => {
// 	return (dispatch, getState) => {
// 		let id = gal._id
// 		console.log(`${url}/api/gallery/${id}`)
// 		console.log(`${url}/api/gallery/imgs`)
// 		axios
// 		.get(`${url}/api/gallery/imgs`)
// 		.then(images => {
// 			dispatch({
// 				type: "GET_IMAGES",
// 				images
// 			})
// 		})
// 		.catch( error => {
// 			console.log(error)
// 		})
// 	}
// }

export const addGallery = (gallery) =>{
	return (dispatch, getState) => {
		console.log(`${url}/api/gallery/upload`)
		console.log()
		axios
		.post(`${url}/api/gallery/upload`, gallery, setHeaders({
				'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
		})
		)
		.then(gallery => {
			dispatch({
				type: "ADD_ART",
				gallery
			})
		})
		.catch( error => {
			console.log(error)
			toast.error(error.response?.data,{
				position: toast.POSITION.BOTTOM_RIGHT
			})

		})
	}
}
