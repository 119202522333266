import React, {useState} from 'react';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import logo from '../../assets/logos/nassos.svg';
import logo2 from '../../assets/logos/bolt.png';
import nassos_gour from '../../assets/photos/nassos_gour450.jpeg';
// Assuming you have an action or function to send the password reset request
import {requestPasswordReset} from "../../store/actions/authActions";
import {useNavigate, useSearchParams} from 'react-router-dom';
import {url} from 'src/api';
import axios from "axios";
import ReCAPTCHA from 'react-google-recaptcha';

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 40px;
    /* justify-content: flex-end; */
    height: 100vh;
    background-color: #a2f4fd;
    text-emphasis-color: #223126;
    @media (max-width: 600px) {
        padding: 25px;
    }

`;

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: right;
    /* justify-content: flex-end; */
    height: inherit;
    background-color: #a2f4fd;
    text-emphasis-color: #223126;
`;

const StyledImg = styled.img`
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 50%;
    margin-top: 10px;
    margin-left: 20px;
    opacity: 0.3;
    /* z-index: 9999; */
    /* position: absolute; */

`;

const StyledForm = styled.form`
    /* border: 1px solid; */
    /* padding: 10px; */
    /* box-shadow: 5px 10px rgba(0, 0, 0, 0.7); */
    /* margin:  20px; */
    /* text-align: left; */
`;

const StyledInput = styled.input`
    ::-webkit-input-placeholder {
        color: #000000ff;
    }

    :-moz-placeholder {
        color: #000000ff;
    }

    ::-moz-placeholder {
        color: #000000ff;
    }

    :-ms-input-placeholder {
        color: #000000ff;
    }

    background-color: #edebff63;
    zoom: 1;
    font-size: 1rem;
    border: 1px solid;
    padding: 5px;
    width: 20rem;
    /* box-shadow: 2px 2px 4px rgba(236, 236, 236, 1); */
    box-shadow: inset 2px 2px #5789ff63;
    margin: 10px;
    transition: 0.2s;

    &:hover {
            /* background-color: ${props => (props.active ? '#f1c17e' : '#f7dc70')}; */
        padding: 7px;
        /* box-shadow: 4px 4px 4px rgba(236, 236, 236, 1); */
    }

    @media (max-width: 600px) {
        width: 85%;
    }

`;

const StyledSubmit = styled.input`
    border: 1px solid;
    padding: 5px;
    /* color: #18d3fd; */
    background-color: #76c6ff;
    box-shadow: 2px 2px 4px rgba(236, 236, 236, 1);
    margin: 10px;
    text-align: center;
        /* background-color: ${props => (props.active ? '#9beece' : 'white')}; */
    transition: padding 0.2s;

    &:hover {
        background-color: ${props => (props.active ? '#807ef1' : '#5273f8')};
        padding: 5px;
    }

    @media (max-width: 600px) {
        height: 20%;
        width: 30%;
    }
`;

const FullContainer = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    /* grid-column-gap: 10px; */
    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
    }
`;

const BoxDiv = styled.div`
    border: 1px solid;
    padding: 10px;
    box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
    margin: 20px;
`;

const H1Style = styled.h1`
    float: right;
`;
const ErrorMessage = styled.p`
    color: #ac0000;
    font-size: 0.8rem;
    margin: 0 10px;
`;

const ResetPasswordPage = () => {
	const navigate = useNavigate();
  // get the param code site.com/reset-password?resetCode=123456 
  const [searchParams] = useSearchParams();
  const resetCode = searchParams.get('resetCode');
	const [identifier, setIdentifier] = useState('');
	const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [captchaValue, setCaptchaValue] = useState(null); 

	const handleChange = (event) => {
		setPassword(event.target.value);

	}

	const handleSubmit = async (event) => {
    error && setError('');
		event.preventDefault();
    if (!captchaValue) {
      setError("Please complete the CAPTCHA");
      return;
    }
		try {
			const response = await axios.post(url + '/api/reset-password/reset', {
				resetToken: resetCode,
        newPassword: password,
        "g-recaptcha-response": captchaValue,
			});

			console.log(response)

			if (response.status === 200) {
				setError('');

			} else {
				// setError(`Error: ${response.status} ${response.data.message}`)
				setError(`Error: ${response.status} `)
				// Handle unsuccessful verification
				// Show an error message
			}
      toast.success(
        `Password reset successfully!`,
        {
          position: 'top-right',
          autoClose: true,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      )
      navigate('/login');
			// navigate('/login'); // Optionally navigate back to login page
		} catch (error) {
			// Handle errors
      setError(`Error: ${error.response.status} ${error.response.data}`);
		}
	}

  const onCaptchaChange = (value) => {
    setCaptchaValue(value); // Update the captcha value state
  }

	return (
		<FullContainer>
			<LoginContainer>
				<link rel="stylesheet"
							href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
				<H1Style>Type your new password for the account.</H1Style>
				<StyledForm onSubmit={handleSubmit}>
					Enter your new password
					<br/>
				
          <StyledInput type={passwordVisible ? "text" : "password"} name="password" placeholder='new password' value={password} onChange={handleChange} required />
          <i className="fa fa-eye" aria-hidden="true" onClick={() => setPasswordVisible(!passwordVisible)}></i> 
					<br/>
          <ReCAPTCHA
            sitekey="6Lc7dRYpAAAAAA3dsXyQRQ8frsFW0VJe0X6eY9aa" 
            onChange={onCaptchaChange}
          />
          <ErrorMessage>{error}</ErrorMessage>
					<StyledSubmit type="submit" value="Reset password"/>
				</StyledForm>
			</LoginContainer>
			<ImageContainer>
				{/* Include any images or logos here */}
				<StyledImg src={nassos_gour} alt="logo"/>
			</ImageContainer>
		</FullContainer>
	);
}

export default ResetPasswordPage;
