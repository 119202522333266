import { React, Component, useEffect } from "react";
// import "./home.css";
import styled from "styled-components";
// import axios from "axios";
import { useState, setState } from "react";
import { useSelector } from "react-redux";
import ArtOfTheDay from "../art_of_the_day/art_of_the_day";
import { Link } from "react-router-dom";
import { logPageView, setUser } from '../../analytics/GoogleAnalytics';
import AotdAuthorsLeaderboard from "../stats_page/AOTDLeaderboard";
import Modal from "../verification/modal";
import axios from "axios";
import { toast } from "react-toastify";
import { url } from "src/api";
import VerificationComponent from "../verification/verification";
import gotemHand from "../../assets/random/gotem-hand.png";

const UnsubPage = () => {
    // create a function that uses /ping/ping/party to ping my backend
    const user = useSelector((state) => state.auth);
    const pingParty = async () => {
        try {
            const response = await axios.get(`${url}/api/ping/ponhroulhhhh/unsubscribe`,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );
        }
        catch (error) {
            console.log(error);
        }
    }

    // // use the pingParty when the page loads
    // componentDidMount() {
    // 	pingParty();
    // }
    useEffect(() => {
        pingParty();
    }, []);

    return (
        <HomeContainer>

            <BoxDivImportant>
                <h2>Haha</h2>
                <img src={gotemHand} alt="gotem hand" />
                <div>
                    {localStorage.getItem("token") ?
                        (<h3>Hi {user.username} ;)</h3>) :
                        (<></>)
                    }
                </div>
            </BoxDivImportant>

        </HomeContainer>
    );
};

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  height: auto;
  min-height: 100vh;
  /* background-color: #c4ffd2; */
  background-color: #b7ffae;
  text-emphasis-color: #223126;
  padding: 15px;
`;


const BoxDivImportant = styled.div`
  border: 4px solid;
  padding: 5px;
  padding-left: 10px;
  box-shadow:   0px 0px 10px 2px rgba(178, 7, 230, 0.907), 5px 10px rgba(0, 0, 0, 0.7) ;
  background-color: #e760ffbc;
  margin: 20px;
  justify-content: start;
  font-weight: 700;
  font-size: 1.5rem;
`;




export default UnsubPage;
