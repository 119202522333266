import React, { useState, useEffect } from 'react';
import axios from 'axios';
import path from 'path';
import './card.css';
import { useParams } from 'react-router-dom';
import { url } from "../../api";
import frame1 from "./assets/frame1.png";
import frame3 from "./assets/frame3.png";
import frappeSketo from "./assets/coffees/frappe-sketo.svg";
import frappeGala from "./assets/coffees/frappe-gala.svg";
import espresso from "./assets/coffees/espresso.svg";
import cappuccino from "./assets/coffees/cappuccino.svg";
import freddoEsp from "./assets/coffees/freddo-esp.svg";
import freddoCap from "./assets/coffees/freddo-cap.svg";
import greek from "./assets/coffees/greek-coffee.svg";
import filter from "./assets/coffees/filter.svg";
import latte from "./assets/coffees/latte.svg";
import icedLatte from "./assets/coffees/iced-latte.svg";

import home from "./assets/map/home.png";

interface DataState {
    mapImgUrl: string;
    qrImgUrl?: string;

    rateColor: string;
    overallRating: number;
    partialRatings: string;

    shopName: string;
    coordinates: string;
    shopLocation: string;
    selfMade: boolean;

    coffee: string;
    coffeeImg: string;
    coffeeFormat: string;
}

const mapping: any = {
    'frappe-sketo': frappeSketo,
    'frappe-gala': frappeGala,
    'espresso': espresso,
    'cappuccino': cappuccino,
    'freddo-esp': freddoEsp,
    'freddo-cap': freddoCap,
    'greek-coffee': greek,
    'filter': filter,
    'latte': latte,
    'iced-latte': icedLatte
};

const CoffeeCard: React.FC = () => {
    const { param } = useParams<{ param: string }>();

    const [data, setData] = useState<DataState>({
        mapImgUrl: '',
        qrImgUrl: '',
        rateColor: '',
        overallRating: 0,
        partialRatings: '',
        shopName: '',
        coordinates: '',
        shopLocation: '',
        coffee: '',
        coffeeFormat: '',
        coffeeImg: '',
        selfMade: false
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch the data from API
                const { data: fetchedData } = await axios.get(`${url}/api/coffee-rate/coffee-card/${param}`);

                // Destructure API response
                const {
                    mapImgUrl,
                    qrImgUrl,
                    rateColor,
                    overallRating,
                    partialRatings,
                    shopName,
                    coordinates,
                    shopLocation,
                    coffee,
                    coffeeFormat,
                    coffeeType
                } = fetchedData;

                // Update state with formatted data
                setData({
                    mapImgUrl,
                    qrImgUrl,
                    rateColor,
                    overallRating,
                    partialRatings,
                    shopName,
                    coordinates,
                    shopLocation,
                    coffee,
                    coffeeFormat,
                    coffeeImg: mapping[coffeeType],
                    selfMade: mapImgUrl === "self-made"
                });



            } catch (error) {
                console.error("Failed to fetch data: ", error);
            }
        };

        fetchData();
    }, [param]);

    return (
        <div className="card">
            <img src={(
                data.mapImgUrl === "self-made" ? home : data.mapImgUrl
            )} alt="Map" className="map-img" />
            <div className="map-text-div">
                <div className="text map-text">{data.shopName}</div>
            </div>
            <div className="map-pin"> </div>
            <img src={frame1} alt="Frame" className="frame" />
            <div className="rate-color" style={{ backgroundColor: data.rateColor }}>
                <div className="rating">{data.overallRating}</div>
            </div>

            <div className="coffee-img-div">
                <img src={data.coffeeImg} alt="Coffee" className="coffee-img" />
            </div>

            <div className="rate-div">
                <div className="rate-bar" style={{ backgroundColor: data.rateColor, width: `${data.overallRating * 10}%` }}></div>
            </div>
            <img src={frame3} alt="Frame" className="frame" />
            <div className="shopname-div">
                <div className="text shopname">{(data.selfMade ? 
                    "Home Made" : data.shopName
                )}</div>
            </div>
            <div className="coor-div">
                <div className="text coordinates">{data.coordinates}</div>
            </div>
            <div className="coffee-div">
                <div className="coffee-type">{data.coffee}</div>
                <div className="coffee-format">{data.coffeeFormat}</div>
            </div>
            <div className="city-div">
                <div className="coffee-text">{data.shopLocation}</div>
            </div>
            <div className="qr-div">
                <img src={data.qrImgUrl} alt="QR Code" className="qr-img" />
            </div>
            <div className="partial-div">
                <div className="partial-text">{data.partialRatings}</div>
            </div>
        </div>
    );
};

export default CoffeeCard;
