import React, { useState, useEffect } from 'react';
import { useLoadStore } from './useLoad';
import { StoreInfo } from './useInfo';
import styled from 'styled-components';
import './store.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faChevronLeft, faChevronRight, faPencil } from '@fortawesome/free-solid-svg-icons';
import { CardContainer, CardTitle, Creator, Description, Price, Size, BuyButton, HeartIcon, StoreImage, ImageContainer, PrevButton, NextButton, LoadingImage } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/types';
import EditCard from './edit_card';
import AddCard from './add_product';
import logo from '../../assets/logos/bolt.png'
import PreOrderCard from './pre-order-card';
import axios from 'axios';
import { url } from 'src/api';
import { is } from 'cypress/types/bluebird';

// import { loadStripe } from "@stripe/stripe-js";
// import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

function checkRole(str: String, searchStr: String) {
  // convert the input string to an array by splitting at each comma
  try {
    var arr = str.split(",");
  } catch {
    return false;
  }

  // loop through the array and check if any element matches the search string
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].trim() === searchStr) {
      return true;
    }
  }
  return false;
}

const StoreCard: React.FC<{ storeInfo: StoreInfo }> = ({ storeInfo }) => {
  const { merchUrls, fetched, loading } = useLoadStore(storeInfo.fileNames);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hover, setHover] = useState(false);

  const user = useSelector((state: RootState) => state.auth);

  const [likes, setLikes] = useState(storeInfo.likesCount || 0);
  const [canLike, setCanLike] = useState(true);
  const [isLiked, setIsLiked] = useState(storeInfo.likes?.includes(user._id as string) || false);


  const [buyPressed, setBuyPressed] = useState(false);

  const [editClicked, setEditClicked] = useState(false)
  const [addClicked, setAddClicked] = useState(false)

  const handleHoverIn = () => setHover(true);
  const handleHoverOut = () => setHover(false);

  const goToNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % merchUrls.length);
  };

  const goToPrevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + merchUrls.length) % merchUrls.length);
  };

  const clickedBuy = () => {
    setBuyPressed(!buyPressed)
  }

  const editClick = () => {
    setEditClicked(!editClicked)
  }

  const addClick = () => {
    setAddClicked(!addClicked)
  }

  const handleLike = async () => {
    if (!canLike) return; // If the like button is not pressable, return

    setCanLike(false); // Set the like button to not pressable
    setIsLiked(isLiked => !isLiked); // Toggle the like state

    try {
      const response = await axios.get(`${url}/api/store/like/${storeInfo.id}`, {
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      });

      if (response.data && response.data.likes) {
        setLikes(response.data.likes.length); // Update likes state
      }
    } catch (error) {
      console.error('Error liking the store:', error);
    }
  };

  useEffect(() => {
    if (!canLike) {
      // If the like button is not pressable, set a timeout to make it pressable after 5 seconds
      const timeoutId = setTimeout(() => {
        setCanLike(true);
      }, 5000);

      // Clear the timeout when the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [canLike]);

  useEffect(() => {
    setLikes(storeInfo.likesCount || 0);
  }, [storeInfo.likesCount]);

  if (editClicked) {
    return (
      <div>
        <EditCard storeInfo={storeInfo} />
        <button className='edit-button' onClick={editClick}>Cancel edit</button>
      </div>
    )
  }

  if (addClicked) {
    return (
      <div>
        <AddCard />
        <button className='add-button' onClick={addClick}>Cancel add</button>
      </div>
    )
  }

  return (
    <div className='store'
    // style={{ backgroundColor: buyPressed ? ' #f787ff58' : '#f787ff0' }}
    >
      {loading ? (
        <>
          <LoadingImage src={logo} alt="Loading..." />
          <div className='light-text'>
            Found merch!
          </div>
        </>
      ) : (
        <>
          <div className="store-title">{storeInfo.title}
            {checkRole(user.role as String, "admin") &&
              <>
                <button className='selection-button' style={{ backgroundColor: '#ffd027' }} onClick={editClick}>
                  <FontAwesomeIcon icon={faPencil as any} />
                </button>
                <button className='selection-button' style={{ backgroundColor: '#90c2ff' }} onClick={addClick}>Add</button>
              </>
            }
          </div>
          <div className='grid-im-creator'>
            <div className="rotated-text">creator: {storeInfo.creator} | created: {storeInfo.dateMade?.toDateString()}</div>
            <div className='im-container' onMouseEnter={handleHoverIn} onMouseLeave={handleHoverOut}>
              <div className='aspect-ratio-box'>
                <img src={merchUrls[currentIndex]} alt="Store" />
              </div>
              {hover && (
                <>
                  <PrevButton className='prev-button' onClick={goToPrevImage}>
                    <FontAwesomeIcon icon={faChevronLeft as any} />
                  </PrevButton>
                  <NextButton className='next-button' onClick={goToNextImage}>
                    <FontAwesomeIcon icon={faChevronRight as any} />
                  </NextButton>
                </>
              )}
            </div>
          </div>
          <div className='grid-desc-pr-size' >
            <div className='store-description'>
              {storeInfo.description}
            </div>
            {/* <div className='price-size-grid'> */}
            <div className='price'>
              €{storeInfo.price}
            </div>
            <div className='size'>
              SZ: {storeInfo.size}
            </div>
            {/* </div> */}
          </div>
          <div className='grid-buy-like' >
            <div className='buyButton'
              onClick={clickedBuy}
              style={{ backgroundColor: buyPressed ? ' #f239ff' : '#444' }}
            >
              <span className='stretchedText'>{buyPressed ? "meh" : "BUY"}</span>
            </div>
            <div className='like-section'>
              <div className={`store-like-button ${isLiked ? 'liked' : ''}`} onClick={handleLike}>
                <span className='like-icon'>{"<3"}</span>
                {/* <span className={isLiked ? 'liked' : 'm'} onClick={handleLike} /> */}
              </div>
              <span className='like-count'>
                <span className='like-zeros'>{String(likes).padStart(6, '0').slice(0, -String(likes).length)}</span>
                <span className='like-actual'>{likes}</span>
              </span>
            </div>
          </div>
          {buyPressed &&
            // <div className='promo-text'>
            //   If want to buy this, contact me in this email
            //   <a href="mailto:nassosbountioukos@gmail.com"> nassosbountioukos@gmail.com </a>
            //   {"or my "}
            //   <a href="https://www.instagram.com/nassos_bountioukos/" target="_blank" rel="noopener noreferrer">instagram</a>
            // </div>
            <PreOrderCard storeInfo={storeInfo} email={user.email as string} />
          }
        </>
      )}
    </div>
  );
};

export default StoreCard;
