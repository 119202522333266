import React, { useEffect, useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  InputToolbox,
  SendButton,
  AttachmentButton,
  TypingIndicator,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import { getMessages, sendMessage } from "../../store/actions/chatActions";
import { useDispatch, useSelector } from "react-redux";
import "./chatStyles.css";

const ChatDiv = () => {
  const user = useSelector((state) => state.auth);
  const { chatID, messages, pending, offensive } = useSelector(
    (state) => state.chat
  );
  const [messagesTemp, setMessagesTemp] = useState(messages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMessages());
  }, [dispatch]);

  useEffect(() => {
    setMessagesTemp(messages);
  }, [messages]);

  const handleSend = (text) => {
    if (text.trim() !== "") {
      const newMessage = {
        content: text,
        role: "user",
        dateSent: new Date(),
      };
      setMessagesTemp([...messagesTemp, newMessage]);
      const payload = {
        message: text,
        dateSent: new Date(),
        id: Math.floor(Math.random() * 999999999) + 1,
      };
      console.log(`offensive state: ${offensive}`);
      dispatch(sendMessage(payload));
      console.log(`offensive state: ${offensive}`);
    }
  };

  console.log(`messsagas: ${messages}`);
  console.log(`offensive: ${offensive}`);

  return (
    <MainContainer className="chat-container">
      <ChatContainer>
        <ConversationHeader>
          {/* <Avatar name="n450s Bot" /> */}
          <ConversationHeader.Content
            userName="n450s Bot"
            info={
              !user.role
                ? "You chat as an unauthenticated user"
                : `You chat as ${user.username}`
            }
          />
        </ConversationHeader>
        <MessageList
          className="message-list"
          typingIndicator={
            pending ? <TypingIndicator content="n450s bot is typing" /> : null
          }
        >
          {messagesTemp && messagesTemp.length > 0 ? (
            messagesTemp.map((msg, index) => (
              <Message
                key={index}
                model={{
                  message: msg.content,
                  sentTime:  msg.timeSent ? msg.timeSent.toString() : "",
                  direction: msg.role === "assistant" ? "incoming" : "outgoing",
                  position:
                    index === messagesTemp.length - 1 ? "last" : "normal",
                }}
              >
                <Message.Footer
                  key={index}
                  sender="---"
                  sentTime={msg.timeSent}
                />
              </Message>
            ))
          ) : (
            <Message
              key={0}
              model={{
                message:
                  "What's up dude? Ask something to start a conversation...",
                sentTime: new Date(),
                sender: "n450s Bot",
                direction: "incoming",
                position: "normal",
              }}
            />
          )}
          {offensive && <OffensiveMessage />}
        </MessageList>

        <MessageInput
          attachButton={false}
          placeholder="Type message here"
          onSend={(text) => handleSend(text)}
        />
      </ChatContainer>
    </MainContainer>
  );
};

const OffensiveMessage = () => {
  return (
    <Message model={{ direction: "incoming" }}>
      <Message.ImageContent src={'https://media.tenor.com/9xpsfn0xH08AAAAC/xios-prosoxi-re-malaka.gif'} alt="Offensive message" width={200} />
    </Message>
  );
};


export default ChatDiv;
