import { React, Component, useEffect } from "react";
// import "./home.css";
import styled from "styled-components";
// import axios from "axios";
import { useState, setState } from "react";
import { useSelector } from "react-redux";
import ArtOfTheDay from "../art_of_the_day/art_of_the_day";
import { Link } from "react-router-dom";
import { logPageView, setUser } from '../../analytics/GoogleAnalytics';
import AotdAuthorsLeaderboard from "../stats_page/AOTDLeaderboard";
import Modal from "../verification/modal";
import axios from "axios";
import { toast } from "react-toastify";
import { url } from "src/api";
import VerificationComponent from "../verification/verification";

function getRoles(str) {
	// convert the input string to an array by splitting at each comma
	var arr = str.split(",");
	// return the array
	return arr;
}

const PartyInvite = () => {

	// create a function that uses /ping/ping/party to ping my backend
	
	const pingParty = async () => {
		try {
			const response = await axios.get(`${url}/api/ping/ping/ping/party`);
		}
		catch (error) {
			console.log(error);
		}
	}

	// // use the pingParty when the page loads
	// componentDidMount() {
	// 	pingParty();
	// }
	useEffect(() => {
		pingParty();
	}, []);

	return (
		<HomeContainer>

			<BoxDivImportant>
				<h2>Wanna join the party?</h2>
				<p>Come to this location at 23:30</p>
				<p><a href="https://maps.app.goo.gl/LMAaGQTRjRpnTu6W9?g_st=ic">GOOGLE MAPS LINK</a></p>
				<h3>COORDINATES: (40.6284329, 22.9588857)</h3>
			</BoxDivImportant>

		</HomeContainer>
	);
};

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  height: auto;
  min-height: 100vh;
  /* background-color: #c4ffd2; */
  background-color: #b7ffae;
  text-emphasis-color: #223126;
  padding: 15px;
`;

const BoxDiv = styled.div`
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
  background-color: #ffe95dbc;
  margin: 20px;
  justify-content: start;
`;

const BoxDiv2 = styled.div`
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
  background-color: #ae5dffbc;
  margin: 20px;
  justify-content: start;
`;

const BoxDivImportant = styled.div`
  border: 4px solid;
  padding: 5px;
  padding-left: 10px;
  box-shadow:   0px 0px 10px 2px rgba(178, 7, 230, 0.907), 5px 10px rgba(0, 0, 0, 0.7) ;
  background-color: #e760ffbc;
  margin: 20px;
  justify-content: start;
  font-weight: 700;
  font-size: 1.5rem;
`;

const BoxDivImportant2 = styled.div`
  border: 4px solid;
  padding: 5px;
  padding-left: 10px;
  box-shadow:   0px 0px 10px 2px rgba(30, 255, 0, 0.907), 5px 10px rgba(0, 0, 0, 0.7) ;
  background-color: #56ff1dbc;
  margin: 20px;
  justify-content: start;
  font-weight: 700;
  font-size: 1.5rem;
`;

const StyledParagraph = styled.p`
  color: #333; // Example color
  font-size: 1rem; // Example font size
  margin: 10px 0; // Example margin
`;

const HighlightedText = styled.p`
  color: #e672e6; // Example highlight color
  font-size: 0.9rem; // Slightly larger font size for emphasis
  margin: 10px 0;
  // font-weight: bold;
`;


class IpCheck extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ip: null,
			country: null,
			city: null,
			isLoading: true,
			provider: null,
			error: false,
		};
	}

	componentDidMount() {
		console.log(this);
		fetch(process.env.REACT_APP_IP_API_LINK)
			.then((response) => response.json())
			.then((response) => {
				this.setState({
					ip: response.ip,
					country: response.country,
					city: response.city,
					provider: response.organization,
					isLoading: false,
				});
			})
			.catch((error) => {
				this.setState({
					error: true,
				});
				console.log(error);
			});
	}

	render() {
		if (this.state.isLoading) {
			return <div>Wait...</div>;
		} else if (!this.state.error) {
			if (
				this.state.provider ===
				"Ote SA (Hellenic Telecommunications Organisation)"
			) {
				return (
					<div>
						<p>
							Your IP is {this.state.ip} and you live in {this.state.country} (
							{this.state.city}). Btw change your provider, cosmote sucks
						</p>
					</div>
				);
			} else {
				return (
					<div>
						<p>
							Your IP is {this.state.ip} and you live in {this.state.country} (
							{this.state.city}){" "}
						</p>
					</div>
				);
			}
		} else {
			return (
				<div>
					<p>There is an error and I can't find where you live</p>
				</div>
			);
		}
	}
}

export default PartyInvite;
