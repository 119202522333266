import React, { useEffect, useState } from "react";
import styles from "./preferences-box.module.css";
import { url } from "../../api/index.js";
import { toast } from "react-toastify";

const PreferencesBox: React.FC = ({
}) => {

	const [preferences, setPreferences] = useState({
		useEmoji: false,
		imgenModel: "dall-e-2",
	});
	const [fetched, setFetched] = useState(false);

	const getPreferences = async () => {
		const token = localStorage.getItem("token");
		const headers: HeadersInit = {
			...(token ? { "x-auth-token": token } : {}),
		};

		const response = await fetch(`${url}/api/edit/preferences`, { headers });
		if (response.status !== 200) {
			return;
		}
		const preferencesData = await response.json();
		setPreferences(preferencesData.artPreferences);
		setFetched(true);
	}

	useEffect(() => {
		getPreferences();
	}, []);

	const token = localStorage.getItem("token");

	const onToggleChange = async (checked: boolean) => {
		// Example POST request (adjust URL and payload as necessary)
		const response = await fetch(`${url}/api/edit/preferences`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				...(token ? { "x-auth-token": token } : {}),
			},
			body: JSON.stringify({ useEmoji: checked }),
		});
		console.log(JSON.stringify({ useEmoji: checked }));
		if (response.ok) {
			setPreferences({ ...preferences, useEmoji: checked });
			toast.success(
				`Emoji preference updated to ${checked ? "enabled" : "disabled"}`,
				{
					position: 'bottom-center',
				}
			);
		} else {
			toast.error("Failed to update emoji preference");
		}
	};

	const onModelChange = async (model: string) => {
		setPreferences({ ...preferences, imgenModel: model });
		// Example POST request (adjust URL and payload as necessary)
		const response = await fetch(`${url}/api/edit/preferences`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				...(token ? { "x-auth-token": token } : {}),
			},
			body: JSON.stringify({ imgenModel: model }),
		});
		console.log(JSON.stringify({ imgenModel: model }));
		if (response.ok) {
			toast.success(`Model preference updated to ${model}`,
				{
          position: 'bottom-center',
				}
			 );
		} else {
			toast.error("Failed to update model preference");
		}
	};

	return ( fetched ? (
		<div className={styles.preferences}>
			<label className={styles.toggleLabel}>
				Use your emoji:
				<input
					type="checkbox"
					checked={preferences.useEmoji}
					onChange={(e) => onToggleChange(e.target.checked)}
				/>
			</label>
			<label className={styles.dropdownLabel}>
				Im/gen model:
				<select value={preferences.imgenModel} onChange={(e) => onModelChange(e.target.value)}>
					<option value="dall-e-2">DALL-E 2</option>
					<option value="claude">Claude</option>
				</select>
			</label>
		</div>
	) : null);
};

export default PreferencesBox;
