import axios from "axios";
import React, { useState, useEffect } from "react";
import { url as apiUrl } from "../../api";

export type MerchInfo = {
  title: string;
  createdBy: string;
  authorQuote: string;
  quoteHelp: number;
  filename: string;
};

export const useLoadStore = (filenames: string[]) => {
  const url = apiUrl as string; 
  const [merchUrls, setMerchUrls] = useState<string[]>([]);
  const [fetched, setFetched] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchArt = async (filename: string) => {
    setLoading(true);
    try {
      const response = await axios.get(
        url + `/api/store/images/${filename}`,
        {
          responseType: "blob",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        const imageUrl = URL.createObjectURL(response.data);
        setMerchUrls((prevUrls) => [...prevUrls, imageUrl]);
      } else {
        console.error("Error fetching merch:", response.status);
      }
    } catch (error) {
      console.error("Error fetching merch:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!fetched) {
      Promise.all(filenames.map(filename => fetchArt(filename)))
        .then(() => setFetched(true))
        .catch((error) => console.error("Error fetching multiple merch:", error));
    }
  }, [url, filenames]);

  return { merchUrls, fetched, loading };
};
