import axios from "axios";
import { useSelector } from "react-redux";
import { url, setHeaders } from "../../api";
import { toast } from "react-toastify";
import { HeaderContent } from "semantic-ui-react";
import { setChatHeaders } from "../../api/chat_headers";


export const getMessages = () => {
  return (dispatch) => {
    axios
      .get(`${url}/api/chat/messages`, 
        setChatHeaders()
      )
      .then((response) => {
        if (response.data.chat_token){
          localStorage.setItem("chat_token", response.data.chat_token);
        }
        if (response.data.messages) {
          dispatch({
            type: "GET_CHAT",
            data: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Cannot fetch messages", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
};

export const sendMessage = (messageToSend) => {
  return (dispatch) => {
    dispatch({
      type: "PENDING_RESPONSE",
      //   message: { ...message, pending: true },
    });

    axios
      .post(`${url}/api/chat/message`, messageToSend, setChatHeaders())
      .then((response) => {
        if(response.status === 403){
          dispatch({
            type: "OFFENSIVE",
          });
          return
        }
				console.log(`send resppp : ${JSON.stringify(response.data)}`);
        dispatch({
          type: "SEND_MESSAGE",
          data: response.data,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          dispatch({
            type: "OFFENSIVE",
          });
          toast.error(error.response?.data.error, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        } else {
          toast.error(error.response?.data.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  };
};
