import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { logPageView } from "../../analytics/GoogleAnalytics";
import axios from "axios";
import { url } from "../../api";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import RecipeDetails from "./items/recipe_details";
import { RecipeInfo } from "./loaders/useInfo";
import { useLoadRecipes } from "./loaders/useInfo";
import "./styles/recipe.css"
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers/types";
import AddRecipe from "./items/add_recipe";
// import { LoadingImage } from "./styles";
import logo from '../../assets/logos/bolt.png'
import RecipeItemSmall from "./items/recipe_item_small";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { parse } from "dotenv";



const RecipePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-color: #f0f0f0;
  padding: 10px;
  height: auto;
  min-height: 100vh;
`;

const Title = styled.h1`
  font-size: 4rem;
  color: #222;
	background-color: transparent;
	text-shadow: 2px 2px 5px #3c3c3cae;
  text-align: center;        /* Center the text since the title is now taking full height */
  margin: 0px;             
  text-transform: uppercase; /* Make text uppercase */
  white-space: nowrap;       /* Prevent line breaks in the title */
  /* position: absolute;        Take the title out of the document flow */
  /* width: 2rem;               Set a fixed width or adjust as needed */
  /* transform: rotate(-90deg); Rotate the title */
  /* transform-origin: center;  Set the rotation point */
  display: flex;             /* Use flexbox for centering */
  align-items: center;       /* Center content vertically */
  justify-content: center;   /* Center content horizontally */
	@media (max-width: 768px) { 
		/* opacity: 0; */
	}	
`;


const Description = styled.p`
  font-size: 1rem;
  color: #555;
  max-width: 800px;
  text-align: center;
  margin-bottom: 2rem;
`;

const parseTitle = (title: string): string => {
	return title.toLowerCase().replace(/\s+/g, '-');
}

const RecipePage: React.FC<{}> = () => {
	const { title } = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const { recipes, loading, serverError } = useLoadRecipes();
	const user = useSelector((state: RootState) => state.auth);
	const [addClicked, setAddClicked] = useState(false);

	const [selectedRecipe, setSelectedRecipe] = useState<RecipeInfo>();

	const handleSelectRecipe = (recipe: RecipeInfo) => {
		setSelectedRecipe(recipe);
		navigate(`/cookbook/${parseTitle(recipe.title)}`);
	};

	const handleClose = () => {
		setSelectedRecipe(undefined); // Clears the selected recipe
		navigate(`/cookbook`);
	};

	useEffect(() => {
		// Check if the user navigated back to the main recipe page
		if (location.pathname === '/cookbook') {
			setSelectedRecipe(undefined);
		}
	}, [location]);

	useEffect(() => {
		if (title) {
			const foundRecipe = recipes.find(r => parseTitle(r.title) === parseTitle(title));
			setSelectedRecipe(foundRecipe);
		} else {
			setSelectedRecipe(undefined);
		}
	}, [title, recipes]);




	const addClick = () => {
		setAddClicked(!addClicked)
	}
	console.log(`info; ${recipes.length}`)

	useEffect(() => {
		document.title = `NΛSOS Recipes`;

	}, []);

	if (serverError) {
		return (
			<RecipePageContainer>
				<div className='recipe'>
					{/* <LoadingImage src={logo} alt="Loading..." /> */}
					<Description>
						Server error...
						<br />
					</Description>
				</div>
			</RecipePageContainer>
		)
	}
	if (loading) {
		return (
			<RecipePageContainer>
				<div className='recipe'>
					{/* <LoadingImage src={logo} alt="Loading..." /> */}
					<Description>
						looking for recipes...
						<br />
						{/* <br />
		the art is created only when someone visits this page. <br />
		whoever visits this page first becomes the author of the art. */}
					</Description>

				</div>

			</RecipePageContainer>
		)
	}
	if ((recipes.length === 0) && !addClicked && !loading) {
		return (
			<RecipePageContainer>
				<div className='recipe'
				// style={{ backgroundColor: buyPressed ? ' #f787ff58' : '#f787ff0' }}
				>
					<div className="recipe-title"
					>{"Items will be added soon"}
						{checkRole(user.role as String, "admin") &&
							<button className='like-button' style={{ backgroundColor: '#90c2ff', padding: '2px' }} onClick={addClick}>Add</button>
						}
					</div>
					<Description>
						The infrastracture is ready. Now just wait for the merch to appear!
						<br />
						{/* <br />
		the art is created only when someone visits this page. <br />
		whoever visits this page first becomes the author of the art. */}
					</Description>

				</div>
			</RecipePageContainer>
		)
	}
	if (addClicked) {
		return (
			<RecipePageContainer>
				<div>
					<AddRecipe />
					<button className='add-button' onClick={addClick}>Cancel add</button>
				</div>
			</RecipePageContainer>
		)
	}
	return (
		<RecipePageContainer>
			<div style={{ display: "flex", flexDirection: "row" }}>
				<Title>Recipes</Title>
				{checkRole(user.role as String, "admin") && (
					<button className='selection-button' onClick={addClick}>Add</button>

				)}

			</div>
			{selectedRecipe ? (
				<RecipeDetails recipeInfo={selectedRecipe} onClose={handleClose} />
			) : (
				recipes.map((recipeInfo, index) => (
					recipeInfo.id !== "" ? (
						<RecipeItemSmall key={index} recipeInfo={recipeInfo} onSelect={() => handleSelectRecipe(recipeInfo)} />
					) : (
						<div>nothing to show</div>
					)
				))
			)}

			<Description>
				The recipe page.
				<br />
				<small><sup>*</sup>This is not your typical recipe page, as I will not be providing you with the exact quantities.

				Given that, the recipe will be done your way. And you never know, you may manage to make it better than me {"(no lol)"}.</small>
			</Description>
		</RecipePageContainer>
	);
};

function checkRole(str: String, searchStr: String) {
	// convert the input string to an array by splitting at each comma
	try {
		var arr = str.split(",");
	} catch {
		return false;
	}

	// loop through the array and check if any element matches the search string
	for (var i = 0; i < arr.length; i++) {
		if (arr[i].trim() === searchStr) {
			return true;
		}
	}
	return false;
}

export default RecipePage;
