import React, { useEffect, useState } from "react";
import styles from "./profile-box.module.css";
import { url } from "../../api/index.js";
import { toast } from "react-toastify";

interface EmojiInputProps {
  onEmojiSubmit: (emoji: string) => void;
}

const EmojiInput: React.FC<EmojiInputProps> = ({ onEmojiSubmit }) => {
  const [newEmoji, setNewEmoji] = useState("");
  const [isAddingEmoji, setIsAddingEmoji] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


  useEffect(() => {
    if (newEmoji.length > 0 && !/\p{Emoji}/u.test(newEmoji)) {
      setErrorMessage("Input must be a single emoji.");
    } else {
      setErrorMessage("");
    }
    // console.log("newEmoji: ", newEmoji);
  }, [newEmoji]);


  const handleEmojiSubmit = async () => {
    const token = localStorage.getItem("token");
    if (newEmoji.length <= 2 && /\p{Emoji}/u.test(newEmoji)) {
      try {
        const headers: HeadersInit = {
          ...(token ? { "x-auth-token": token } : {}),
        };

        // Example POST request (adjust URL and payload as necessary)
        const response = await fetch(`${url}/api/edit/add-emoji`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...(token ? { "x-auth-token": token } : {}),
          },
          body: JSON.stringify({ emoji: newEmoji }), 
        });
        console.log(JSON.stringify({ emoji: newEmoji }));


        if (response.ok) {
          onEmojiSubmit(newEmoji);
          setIsAddingEmoji(false);
          setNewEmoji("");
          toast.success(`Emoji submitted successfully! ${newEmoji} ${newEmoji} ${newEmoji}`);
        } else {
          console.error("Failed to submit emoji:", response.statusText);
          //print the error message
          const data = await response.json();
          toast.error(`Failed to submit emoji: ${data.error}`);
        }
      } catch (error) {
        console.error("Error submitting emoji:", error);
        toast.error("Failed to submit emoji. Please try again.");
      }
    } else {
      setErrorMessage("Input must be a single emoji.");
    }
  };

  return (
    <div className={styles.emojiInputGroup}>
      {isAddingEmoji ? (
        <>
          <span className={styles.emojiLabel}>Add your emoji:</span>
          <div className={styles.emojiInputContainer}>
            <input
              type="text"
              value={newEmoji}
              onChange={(e) => setNewEmoji(e.target.value)}
              maxLength={2}
              className={styles.emojiInput}
              placeholder=""
            />
            <button
              className={styles.submitButton}
              onClick={handleEmojiSubmit}
              disabled={!!errorMessage || newEmoji.length === 0}
            >
              Submit
            </button>
            <button
              className={styles.cancelButton}
              onClick={() => setIsAddingEmoji(false)}
            >
              Cancel
            </button>
          </div>
          {errorMessage && (
            <span className={styles.errorMessage}>{errorMessage}</span>
          )}
        </>
      ) : (
        <button
          className={styles.addEmojiButton}
          onClick={() => setIsAddingEmoji(true)}
        >
          Add Emoji
        </button>
      )}
    </div>
  );
};

export default EmojiInput;
