import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { logPageView } from "../../analytics/GoogleAnalytics";
import axios from "axios";
import { url } from "../../api";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import StoreCard from "./store_card";
import { MerchInfo, useLoadStore } from "./useLoad";
import { useLoadInfo } from "./useInfo";
import "./store.css"
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers/types";
import AddCard from "./add_product";
import { LoadingImage } from "./styles";
import logo from '../../assets/logos/bolt.png'

type MerchItem = {
	title: string;
	creator?: string;
	description?: string;
	dateAdded?: string;  // Adjust the type based on your needs
	dateMade?: string;   // Adjust the type based on your needs
	imageId?: string;
	addedBy?: string;
	price?: number;
	stock?: number;
	size?: string;
	merchType?: string;
	productNumber?: number;
	selected?: boolean;
	inGallery?: boolean;
	fileNames: string[]; // fileNames is an array of strings
};

const StorePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background-color: #f0f0f0;
  padding: 15px;
  height: auto;
  min-height: 100vh;
`;

const Title = styled.h1`
  font-size: 8rem;
  color: #222;
  text-align: center;        /* Center the text since the title is now taking full height */
  margin: 10px;                 /* Reset margins */
  text-transform: uppercase; /* Make text uppercase */
  white-space: nowrap;       /* Prevent line breaks in the title */
  position: absolute;        /* Take the title out of the document flow */
  top: 0;                    /* Start from the top */
  left: 0;                   /* Place the title on the left side */
  bottom: 0;                 /* Stretch it to the bottom */
  width: 2rem;               /* Set a fixed width or adjust as needed */
  transform: rotate(-90deg); /* Rotate the title */
  transform-origin: center;  /* Set the rotation point */
  display: flex;             /* Use flexbox for centering */
  align-items: center;       /* Center content vertically */
  justify-content: center;   /* Center content horizontally */
	@media (max-width: 768px) { 
		opacity: 0;
	}	
`;


const Description = styled.p`
  font-size: 1rem;
  color: #555;
  max-width: 800px;
  text-align: center;
  margin-bottom: 2rem;
`;

const StorePage: React.FC<{}> = ({ }) => {
	const [loading, setLoading] = useState(true);
	const { storeInfos, loading: hookLoading } = useLoadInfo();
	const location = useLocation();

	const user = useSelector((state: RootState) => state.auth);

	const [addClicked, setAddClicked] = useState(false)

	const addClick = () => {
		setAddClicked(!addClicked)
	}
	console.log(`info; ${storeInfos.length}`)

	useEffect(() => {
		document.title = `NΛSOS Store`;

	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(hookLoading);
		}, 400);  

		// Cleanup function to clear the timeout if the component is unmounted before the timeout finishes
		return () => {
			clearTimeout(timer);
		};
	}, [hookLoading]);

	if (loading) {
		return (
			<StorePageContainer>
				 <div className='store'>
				<LoadingImage src={logo} alt="Loading..." />
				<Description>
					looking for merch...
					<br />
					{/* <br />
		the art is created only when someone visits this page. <br />
		whoever visits this page first becomes the author of the art. */}
				</Description>

				</div>

			</StorePageContainer>
		)
	}

	if ((storeInfos.length === 0) && !addClicked && !loading) {
		return (
			<StorePageContainer>
				<div className='store'
				// style={{ backgroundColor: buyPressed ? ' #f787ff58' : '#f787ff0' }}
				>
					<div className="store-title"
					>{"Items will be added soon"}
						{checkRole(user.role as String, "admin") &&
							<button className='like-button' style={{ backgroundColor: '#90c2ff', padding: '2px' }} onClick={addClick}>Add</button>
						}
					</div>
					<Description>
						The infrastracture is ready. Now just wait for the merch to appear!
						<br />
						{/* <br />
		the art is created only when someone visits this page. <br />
		whoever visits this page first becomes the author of the art. */}
					</Description>

				</div>
			</StorePageContainer>
		)
	}

	if (addClicked) {
		return (
			<StorePageContainer>
				<div>
					<AddCard />
					<button className='add-button' onClick={addClick}>Cancel add</button>
				</div>
			</StorePageContainer>
		)
	}


	return (
		<StorePageContainer>
			{/* <Title>Store</Title> */}
			{storeInfos.map((storeInfo, index) => (
				storeInfo.fileNames.length > 0 ? (
					<StoreCard key={index} storeInfo={storeInfo} />
				) : null
			))}
			<Description>
				The store page.
				<br />
				{/* <br />
		the art is created only when someone visits this page. <br />
		whoever visits this page first becomes the author of the art. */}
			</Description>
		</StorePageContainer>
	);
};

function checkRole(str: String, searchStr: String) {
	// convert the input string to an array by splitting at each comma
	try {
		var arr = str.split(",");
	} catch {
		return false;
	}

	// loop through the array and check if any element matches the search string
	for (var i = 0; i < arr.length; i++) {
		if (arr[i].trim() === searchStr) {
			return true;
		}
	}
	return false;
}

export default StorePage;
