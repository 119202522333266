import axios from "axios";
import React, { useState, useEffect } from "react";
import { url as apiUrl } from "../../api";

export type ArtInfo = {
  title: string;
  createdBy: string;
  authorQuote: string;
  quoteHelp: number;
  filename: string;
  date: Date;
  views: number;
};

export const useLoadInfo = () => {
  const url = apiUrl as string; // Add a type assertion here
  const [artInfos, setArtInfos] = useState<ArtInfo[]>([]); // Now this is an array

  const fetchArtInfo = async () => {
    try {
      const response = await axios.get(url + `/api/art-of-the-day/all/info`);

      if (response.data.message) {
        setArtInfos([]);
      } else {
        // Assuming the response data is an array of art info objects
        setArtInfos(
          response.data.map((info: any) => ({
            title: info.title,
            createdBy: info.createdBy,
            authorQuote: info.authorQuote,
            quoteHelp: parseInt(info.quoteHelp),
            filename: info.filename,
            date: new Date(info.date),
            views: parseInt(info.views),
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching INFO:", error);
    }
  };

  useEffect(() => {
    fetchArtInfo();
  }, [url]);

  return { artInfos };
};
