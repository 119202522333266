import { React, Component, useEffect } from "react";
// import "./home.css";
import styled from "styled-components";
// import axios from "axios";
import { useState, setState } from "react";
import { useSelector } from "react-redux";
import ArtOfTheDay from "../art_of_the_day/art_of_the_day";
import { Link } from "react-router-dom";
import { logPageView, setUser } from '../../analytics/GoogleAnalytics';
import AotdAuthorsLeaderboard from "../stats_page/AOTDLeaderboard";
import Modal from "../verification/modal";
import axios from "axios";
import { toast } from "react-toastify";
import { url } from "src/api";
import VerificationComponent from "../verification/verification";

function getRoles(str) {
  // convert the input string to an array by splitting at each comma
  var arr = str.split(",");
  // return the array
  return arr;
}

const HomePage = () => {
  const user = useSelector((state) => state.auth);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  useEffect(() => {
    logPageView();
    if (user.username) {
      setUser(user.username);
    }
  }, [user.username]);
  useEffect(() => {
    document.title = `NΛSOS`;
    checkVerificationStatus();
  }, []);

  const checkVerificationStatus = async () => {
    console.log('API URL:', url);
    console.log(`Environment: ${process.env.REACT_APP_SERVER_URL}`)
    try {
      const response = await axios.get(`${url}/api/verify/huh`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'), // Assuming you're storing the token in localStorage
        },
      });

      if (response.status === 200 && !response.data.verified) {
        setShowVerificationModal(true);
        // toast in the botom left
        toast.info(
          'Your email is not verified. Please press the verify button to verify your email',
          {
            position: 'bottom-left',
            autoClose: true,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );

      }
    } catch (error) {
      console.error('Error checking verification status:', error);
    }
  };


  return (
    <HomeContainer>
      <div>
        <h1>This is the NΛSOS website</h1>
        <h3><i>The website is under construction and will stay like this for a looong time...</i></h3>
        {user._id ? (
          <h2>Your rank is "{user.role.split(", ")}"</h2>
        ) : (
          // <h2>{'Your rank is "not-even-a-member", lol, sign up'}</h2>
          // <h2>{'Your rank is "not-a-member"'}</h2>
          <h2></h2>
        )}
        {/* <IpCheck /> */}

        {showVerificationModal && (
          // <Modal
          //   onClose={() => setShowVerificationModal(false)}
          //   onConfirm={handleSendVerificationEmail}
          // >
          //   <StyledParagraph>Your email is not verified. Do you want to send a verification email?</StyledParagraph>
          //   <HighlightedText>The email will be sent to {user.email}</HighlightedText>
          // </Modal>
          <VerificationComponent />
        )}
      </div>

      {/* <BoxDivImportant2 >
        <p>Hungry?</p>
        <p>Try a sexy recipe in the new <Link to="/cookbook">section</Link>😉</p>
      </BoxDivImportant2> */}

      <BoxDivImportant2 >
        <p>Emojis!!!</p>
        <p>You can now pick your own emoji. Visit <Link to="/profile">your profile</Link> to pick it 😉</p>
      </BoxDivImportant2>

      {/* <BoxDivImportant>
        <p>THE STORE IS READY.</p>
        <p>items will be added soon.</p>
      </BoxDivImportant> */}
      {/* <BoxDiv2>
        <p>now you can see all the arts of the day on the <Link to="/art-of-the-day/gallery">AOTD gallery</Link> page!</p>
        <p>{`sign up to post one;)`}</p>
      </BoxDiv2> */}
      {/* <BoxDiv>
        <p>THE STORE IS READY.</p>
        <p>items will be added soon.</p>
      </BoxDiv> */}

      <AotdAuthorsLeaderboard />
      {/* <BoxDiv>
        <div style={{ alignItems: "center" , display: "flex", flexDirection: "column"}}>
          <h2>Art of the Day</h2>
        
        <Link to="/art-of-the-day">
          <ArtOfTheDay />
        </Link>
				
        new art every night at 4:50 UTC
				</div>
      </BoxDiv> */}
    </HomeContainer>
  );
};

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  height: auto;
  min-height: 100vh;
  /* background-color: #c4ffd2; */
  background-color: #b7ffae;
  text-emphasis-color: #223126;
  padding: 15px;
`;

const BoxDiv = styled.div`
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
  background-color: #ffe95dbc;
  margin: 20px;
  justify-content: start;
`;

const BoxDiv2 = styled.div`
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
  background-color: #ae5dffbc;
  margin: 20px;
  justify-content: start;
`;

const BoxDivImportant = styled.div`
  border: 4px solid;
  padding: 5px;
  padding-left: 10px;
  box-shadow:   0px 0px 10px 2px rgba(255, 111, 0, 0.907), 5px 10px rgba(0, 0, 0, 0.7) ;
  background-color: #ff611dbc;
  margin: 20px;
  justify-content: start;
  font-weight: 700;
  font-size: 1.5rem;
`;

const BoxDivImportant2 = styled.div`
  border: 4px solid;
  padding: 5px;
  padding-left: 10px;
  box-shadow:   0px 0px 10px 2px rgba(30, 255, 0, 0.907), 5px 10px rgba(0, 0, 0, 0.7) ;
  background-color: #56ff1dbc;
  margin: 20px;
  justify-content: start;
  font-weight: 700;
  font-size: 1.5rem;
`;

const StyledParagraph = styled.p`
  color: #333; // Example color
  font-size: 1rem; // Example font size
  margin: 10px 0; // Example margin
`;

const HighlightedText = styled.p`
  color: #e672e6; // Example highlight color
  font-size: 0.9rem; // Slightly larger font size for emphasis
  margin: 10px 0;
  // font-weight: bold;
`;


class IpCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ip: null,
      country: null,
      city: null,
      isLoading: true,
      provider: null,
      error: false,
    };
  }

  componentDidMount() {
    console.log(this);
    fetch(process.env.REACT_APP_IP_API_LINK)
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          ip: response.ip,
          country: response.country,
          city: response.city,
          provider: response.organization,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error: true,
        });
        console.log(error);
      });
  }

  render() {
    if (this.state.isLoading) {
      return <div>Wait...</div>;
    } else if (!this.state.error) {
      if (
        this.state.provider ===
        "Ote SA (Hellenic Telecommunications Organisation)"
      ) {
        return (
          <div>
            <p>
              Your IP is {this.state.ip} and you live in {this.state.country} (
              {this.state.city}). Btw change your provider, cosmote sucks
            </p>
          </div>
        );
      } else {
        return (
          <div>
            <p>
              Your IP is {this.state.ip} and you live in {this.state.country} (
              {this.state.city}){" "}
            </p>
          </div>
        );
      }
    } else {
      return (
        <div>
          <p>There is an error and I can't find where you live</p>
        </div>
      );
    }
  }
}

export default HomePage;
